import React, { Suspense } from "react";
import Footer from '../layout/footer';
import swichbannerimg from '../img/switch_banner.png'
import BannerForm from "../home/Banner-form"
import switchpgmImg1 from "../img/switch_our_programs_image.jpg"
import step_1_img from '../img/step_1.png'
import step_2_img from '../img/step_2.png'
import step_3_img from '../img/step_3.png'
import step_4_img from '../img/step_4.png'
import $ from 'jquery';
const Header = React.lazy(() => import("../layout/header"));

function SwitchBanner() {
  return (
    <div className='main_wrapper'>
		<Suspense fallback={<div></div>}>
			<Header />
		</Suspense>
        <section className="breadcrumbs_module">
		<div className="container-fluid">
			<div className="row">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<ul>
								<li><a href="/">Home</a></li>
								<li><a href="javacript:void(0)">Our programs</a></li>
								<li>SwiTch</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    <section className="page_intro_module switch_careers_module clearfix">
		<div className="container-fluid">
			<div className="row">
				<div className="container pi_posr">
					<div className="row">
						<div className="col-md-8">
							<h1 className="pi_heading">SwiTch</h1>
							<div className="row">
								<div className="col-md-12">
									<div className="pi_banner">
										<img src={swichbannerimg} alt="About Banner"
											title="About Banner" className="img-fluid" />
									</div>
									<div className="pi_content">
										<h3>The opportunity to explore multiple career options in the field of Information Technology</h3>
									</div>
								</div>
							</div>
						</div>
                        <div className='col-md-4'>
						<BannerForm fromPage={"switch"}></BannerForm>
						</div>
					</div>
					<div className="row pi_abs">
						<div className="container">
							<div className="pi_img">
								<div className="col-md-12">
									<img src={swichbannerimg} alt="First IT Careers Banner"
										title="First IT Careers Banner" className="img-fluid" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    <section className="our_programs_module">
		<div className="container-fluid">
			<div className="row">
				<div className="container">
					<div className="row opm_block">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-12">
									<h2>OUR PROGRAMS</h2>
								</div>
							</div>
							<div className="row opmb_col">
								<div className="col-md-6">
									<p>At Aptech IT Careers powered by HCL Technologies, we offer industry-oriented career courses to <strong>non-IT graduates.</strong> Our programs extend beyond the typical classroom learning that enables multiple viable career opportunities to young professionals in the field of Information Technology. </p>
								</div>
								<div className="col-md-6">
									<p>The program is of <strong>6 months with extensive concept learning (Instructor Led and Self-paced)</strong> starting from fundamentals and along with hands-on practicum (including coding labs, Workware, and Mini-projects applicable to the program track). After successful course completion, the ICAP team provides you with <strong>job placement assistance.</strong></p>
								</div>
							</div>
						</div>

						<div className="col-md-12">
							<div className="row">
								<div className="col-md-12">
									<h3>SwiTch </h3>
								</div>
							</div>
							<div className="row opmb_col">
								<div className="col-md-6">
									<p>The advantage of joining the SwiTch program at Aptech IT Careers powered by HCL Technologies is the <strong>opportunity offered to identify your first job with leading Technology & IT services companies </strong> and train you to be successful in it within six months of joining. You will also get assistance in placement* at the end of the training.</p>
								</div>
								<div className="col-md-6">
									<p>Some major courses offered under the SwiTch program are <strong>Linux, Windows, and Data Engineering.</strong></p>
								</div>
							</div>
							<div className="row">
								<div className="col-md-9">
									<p>By joining the SwiTch program, you can choose technology categories from:</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="container">
					<div className="row opm_table">
						<div className="col-md-12">
							<div className="row opmt_switch">
								<div className="col-md-7">
									<div className="table-responsive">
										<table className="table table-bgcareer">
											<thead>
												<tr>
													<th>Technology</th>
													<th>Job pack</th>
													<th>Hours*</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Cloud Services</td>
													<td>Cloud Engineer</td>
													<td>792</td>
												</tr>
												<tr>
													<td>Network Support Engineer</td>
													<td>Network Administration</td>
													<td>792</td>
												</tr>
												<tr>
													<td rowspan="2">Server Administration</td>
													<td>Linux</td>
													<td>792</td>
												</tr>
												<tr>
													<td>Windows</td>
													<td>792</td>
												</tr>
												<tr>
													<td>Java</td>
													<td>Web Application Development</td>
													<td>720</td>
												</tr>
												<tr>
													<td>.Net</td>
													<td>Web Application Development</td>
													<td>720</td>
												</tr>
												<tr>
													<td>Data Engineering</td>
													<td>Data Engineering (Python / Data Warehousing / Big Data / Hadoop / Tableau)	</td>
													<td>720</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="approximately">
										<p>*Approximately</p>
									</div>
								</div>
								<div className="col-md-5">
									<div className="our_programs_image">
										<img src={switchpgmImg1} alt="First IT Careers Banner"
											title="First IT Careers Banner" className="img-fluid"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="op_courses_module">
		<div className="container-fluid">
			<div className="row">
				<div className="container">
					<div className="row opm_block">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-3 no_pr">
									<div className="row">
										<div className="col-md-12">
											<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
												aria-orientation="vertical">
												<a className="nav-link active" id="opmb_1_tab" data-toggle="pill"
													href="#opmb_s_1" role="tab" aria-controls="opmb_s_1"
													aria-selected="true">
													<span className="opct_head">Cloud Services</span>
													<span className="opct_subtitle">Cloud Engineer</span>
												</a>
												<a className="nav-link" id="opmb_2_tab" data-toggle="pill"
													href="#opmb_s_2" role="tab" aria-controls="opmb_s_2"
													aria-selected="false">
													<span className="opct_head">Network Support Engineer</span>
													<span className="opct_subtitle">Network Administration</span>
												</a>
												<a className="nav-link" id="opmb_3_tab" data-toggle="pill"
													href="#opmb_s_3" role="tab" aria-controls="opmb_s_3"
													aria-selected="false">
													<span className="opct_head">Server Administration</span>
													<span className="opct_subtitle">Linux</span>
												</a>
												<a className="nav-link" id="opmb_4_tab" data-toggle="pill"
												href="#opmb_s_4" role="tab" aria-controls="opmb_s_4"
												aria-selected="true">
													<span className="opct_head">Server Administration</span>
													<span className="opct_subtitle">Windows</span>
												</a>
												<a className="nav-link" id="opmb_5_tab" data-toggle="pill"
													href="#opmb_s_5" role="tab" aria-controls="opmb_s_5"
													aria-selected="false">
													<span className="opct_head">Java</span>
													<span className="opct_subtitle">Web Application Development</span>
												</a>
												<a className="nav-link" id="opmb_6_tab" data-toggle="pill"
													href="#opmb_s_6" role="tab" aria-controls="opmb_s_6"
													aria-selected="false">
													<span className="opct_head">.NET</span>
													<span className="opct_subtitle">Web Application Development</span>
												</a>
												<a className="nav-link" id="opmb_7_tab" data-toggle="pill"
												href="#opmb_s_7" role="tab" aria-controls="opmb_s_7"
												aria-selected="true">
													<span className="opct_head">Data Engineering </span>
													<span className="opct_subtitle">Data Engineering</span>
													<span className="opct_tagline">(Python / Data Warehousing / Big Data / Hadoop / Tableau)</span>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-9 no_pl">
									<div className="row">
										<div className="col-md-12">
											<div className="tab-content" id="v-pills-tabContent-switch">
												<div className="card tab-pane fade show active" id="opmb_s_1" role="tabpanel"
													aria-labelledby="opmb_s_1_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-s_1">
														<a data-toggle="collapse" href="#v-collapse-s_1"
															data-parent="#v-pills-tabContent-switch" aria-expanded="true"
															aria-controls="v-collapse-s_1">
															<span className="opct_head">Cloud Services</span>
															<span className="opct_subtitle">Cloud Engineer</span>
														</a>
													</div>
													<div id="v-collapse-s_1" className="collapse show" role="tabpanel"
														aria-labelledby="v-collapse-heading-s_1"
														data-parent="#v-pills-tabContent-switch">
														<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Understand cloud computing infrastructure; security studies, scaling, deployment and backup, in the context of cloud infrastructure</li><li>Understand the learning techniques of cloud programming and development of system administration skills for the development and maintenance of applications deployed in the cloud on Azure, AWS, Google</li><li>Work on Identity Access Management (IAM) and managed services</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Analyst</li><li>Engineer</li><li>Consultant</li><li>Sr Consultant</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>6 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}
												<div className="card tab-pane fade" id="opmb_s_2" role="tabpanel"
													aria-labelledby="opmb_s_2_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-s_2">
														<a data-toggle="collapse" href="#v-collapse-s_2"
															data-parent="#v-pills-tabContent-switch" aria-expanded="false"
															aria-controls="v-collapse-s_2">
															<span className="opct_head">Network Support Engineer</span>
															<span className="opct_subtitle">Network Administration</span>
														</a>
													</div>
													<div id="v-collapse-s_2" className="collapse" role="tabpanel"
														aria-labelledby="v-collapse-heading-s_2"
														data-parent="#v-pills-tabContent-switch">
														<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Demonstrate the ability to setup, configure, troubleshoot, secure and maintain a microcomputer to include hardware and operating system software</li><li>Design and manage a computer network to include installing, configuring, securing and troubleshooting network devices, protocols and services</li><li>Apply networking skills related to server operating systems, directory services and administrative network policy making</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Analyst</li><li>Engineer</li><li>Consultant</li><li>Sr Consultant</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>6 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}
												<div className="card tab-pane fade" id="opmb_s_3" role="tabpanel"
														aria-labelledby="opmb_s_3_tab">
														<div className="card-header" role="tab" id="v-collapse-heading-3">
															<a data-toggle="collapse" href="#v-collapse-s_3"
																data-parent="#v-pills-tabContent-switch" aria-expanded="false"
																aria-controls="v-collapse-s_3">
																<span className="opct_head">Server Administration</span>
																<span className="opct_subtitle">Linux</span>
															</a>
														</div>
														<div id="v-collapse-s_3" className="collapse" role="tabpanel"
															aria-labelledby="v-collapse-heading-s_3"
															data-parent="#v-pills-tabContent-switch">
															<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p>
															<ul>
															<li>Install and configure the Linux operating system, manage the resources and security of a computer running Linux at a basic level and make effective use of Unix utilities, and scripting languages</li>
															<li>Configure and manage simple TCP/IP network services on a Linux system and handle basic administrative tasks such as creating and managing users, creating and maintaining file systems, determining and implementing security measures, and performing software installation and package management</li>
															<li>Apply technical knowledge to manage servers, investigate the layout of server file systems, plan, create and manage information services</li>
															</ul>
															<div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Analyst</li><li>Administrator</li><li>Consultant/ SME</li><li>Sr. Consultant / Architect</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>6 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year</li></ul></div></div></div>
														</div>
												</div>
												{/* /.tab-pane */}
												<div className="card tab-pane fade" id="opmb_s_4" role="tabpanel"
													aria-labelledby="opmb_s_4_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-s_4">
														<a data-toggle="collapse" href="#v-collapse-s_4"
															data-parent="#v-pills-tabContent-switch" aria-expanded="true"
															aria-controls="v-collapse-s_4">
															<span className="opct_head">Server Administration</span>
														<span className="opct_subtitle">Windows</span>
														</a>
													</div>
													<div id="v-collapse-s_4" className="collapse" role="tabpanel"
														aria-labelledby="v-collapse-heading-s_4"
														data-parent="#v-pills-tabContent-switch">
														<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Learn the basics of installing, administrating and maintaining a Windows Server implementation</li><li>Administration of user and group accounts, establish Group Policy Management</li><li>Active Directory, network protocols and services such as Virtual Private Networking</li><li>Routing and Remote Access Service, DHCP, DNS, backup, recovery and disaster planning</li><li>FTP Server Installation, Configuration and Management</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>Analyst</li><li>Administrator</li><li>Consultant/ SME</li><li>Sr. Consultant / Architect</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>6 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}
												<div className="card tab-pane fade" id="opmb_s_5" role="tabpanel"
													aria-labelledby="opmb_s_5_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-s_5">
														<a data-toggle="collapse" href="#v-collapse-s_5"
															data-parent="#v-pills-tabContent-switch" aria-expanded="true"
															aria-controls="v-collapse-s_5">
															<span className="opct_head">Java</span>
															<span className="opct_subtitle">Web Application Development</span>
														</a>
													</div>
													<div id="v-collapse-s_5" className="collapse" role="tabpanel"
														aria-labelledby="v-collapse-heading-s_5"
														data-parent="#v-pills-tabContent-switch">
														<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Structure and implement HTML/CSS</li><li>Apply intermediate and advanced web development practices</li><li>Implement basic JavaScript and create visualizations in accordance with UI/UX theories</li><li>Develop a fully functioning website and deploy on a web server</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>Developer</li><li>FS Cloud Developer</li><li>Microservices Specialist</li><li>SW Solution Architect</li></ul><p><strong>Horizontal Pathways</strong></p><ul><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>6 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}
												<div className="card tab-pane fade" id="opmb_s_6" role="tabpanel"
													aria-labelledby="opmb_s_6_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-s_6">
														<a data-toggle="collapse" href="#v-collapse-s_6"
															data-parent="#v-pills-tabContent-switch" aria-expanded="true"
															aria-controls="v-collapse-s_6">
															<span className="opct_head">.Net</span>
															<span className="opct_subtitle">Web Application Development</span>
														</a>
													</div>
													<div id="v-collapse-s_6" className="collapse" role="tabpanel"
														aria-labelledby="v-collapse-heading-s_6"
														data-parent="#v-pills-tabContent-switch">
														<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Describe what web applications are and how they are created using .NET</li><li>Understand ASP web form, server controls, and understand the separation between a web page, page code, code-behind pages, page controls, and components</li><li>Connect the web pages with data source and to apply validations on data entered by a user</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>Developer</li><li>FS Cloud Developer</li><li>Microservices Specialist</li><li>SW Solution Architect</li></ul><p><strong>Horizontal Pathways</strong></p><ul><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>6 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}
												<div className="card tab-pane fade" id="opmb_s_7" role="tabpanel"
													aria-labelledby="opmb_s_7_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-7">
														<a data-toggle="collapse" href="#v-collapse-s_7"
															data-parent="#v-pills-tabContent-switch" aria-expanded="true"
															aria-controls="v-collapse-s_7">
															<span className="opct_head">Data Engineering</span>
															<span className="opct_subtitle">Data Engineering</span>
															<span className="opct_tagline">(Python / Data Warehousing / Big Data / Hadoop / Tableau)</span>
														</a>
													</div>
													<div id="v-collapse-s_7" className="collapse" role="tabpanel"
														aria-labelledby="v-collapse-heading-s_7"
														data-parent="#v-pills-tabContent-switch">
														<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Design, implement, and evaluate the use of analytic algorithms on sample datasets, explain how a machine-learning model is developed for and evaluated on datasets</li><li>Design and execute experimental data collection and processing and present resulting analyses using best practices in data communications, apply and customize analytics, systems, and techniques to application-specific data engineering requirements and objectives</li><li>Identify tradeoffs among data engineering techniques and contrast design alternatives within the context of specific data engineering application domains</li><li>Program with Python and build complex data architecture, manage data pipelines and data processes to ensure correct implementation of your data architecture</li><li>Use data wrangling to clean, reshape, and unify multiple datasets and large amounts of data to be organized for analysis and automate tasks to optimize the entire data workflow</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>Data Engineer</li><li>Data Scientist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>6 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row opm_enrolment">
						<div className="col-md-9 offset-md-3">
							<div className="row opme_block">
								<div className="col-md-12">
									<h2>Enrolment procedure</h2>
									{/* <ol>
										<li>Online Counselling</li>
										<li>Registration / Enrolment</li>
										<li>Online Aptitude Test (Learning and Coding)</li>
										<li>Choose Technology streams</li>
									</ol>  */}
									<ul>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 1</span>
											<span className="opm_img">
												<img src={step_1_img} alt="Step 1"  className="img-fluid"/>
											</span>
											<span className="opm_title">Online Counselling</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 2</span>
											<span className="opm_img">
												<img src={step_2_img} alt="Step 2"  className="img-fluid"/>
											</span>
											<span className="opm_title">Registration / Enrolment</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 3</span>
											<span className="opm_img">
												<img src={step_3_img} alt="Step 3"  className="img-fluid"/>
											</span>
											<span className="opm_title">Online Aptitude Test (Learning and Coding)</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 4</span>
											<span className="opm_img">
												<img src={step_4_img} alt="Step 4"  className="img-fluid"/>
											</span>
											<span className="opm_title">Choose Technology streams</span>
										</li>
									</ul>
									<div className="opme_btn">
										<a href="javascript:void(0);" className="gotoForm">Apply for a course now</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-8 offset-md-2">
							<div className="row opme_tagline">
								<div className="col-md-12">
									<p>Interested in any of our courses? Fill in the <a href="javascript:void(0);" className="gotoForm">student enquiry form</a> here and our career counsellor will contact you</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="container">
					<div className="row am_small">
						<div className="col-md-12">
							<p><sup>*</sup>Aptech IT Careers powered by HCL Technologies does not guarantee job placements. Placements are at the sole discretion of recruiters.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	
        <Footer/>
    </div>
  )
}

export default SwitchBanner