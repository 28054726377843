
import BannerForm from "../home/Banner-form"
import '../css/owl.carousel.min.css'
import '../css/owl.theme.default.min.css'
import Round from'../img/round_1.png';
import Icon1 from'../img/icon1.png';
import Icon2 from'../img/icon2.png';
import Icon3 from'../img/icon3.png';
import banner from '../img/campaingn/banner2.png'
import banner3 from '../img/campaingn/banner3.png'
import iconCalender from '../img/campaingn/icon-calender.png'
import iconCounsling from '../img/campaingn/icon-counselling.png'
import iconPhone from '../img/campaingn/icon-phone.png'
function Slider(){
	
    return(
        <section className="career_module clearfix">
			<div className="container-fluid">
				<div className="row">
					<div className="container posr">
						<div className="row cm_cnt">
							<div className="col-md-12 cmc_posr">
								<div className="cm_slider owl-carousel owl-theme">
									<div className="cms_block cms_banner_1">
										<div className="cncb_image">
											<img src={Round} alt="Banner 1 Image" className="img-fluid" />
										</div>
										<div className="cms_cont">
											<h2>A career course in Technology and IT Services that provide:</h2>
											<div className="row">
												<div className="col-md-4">
													<div className="cm_img cm_img_1">
														<img src={Icon1} alt="Chance to work with global technology and IT services companies" />
													</div>
													<h4>Chance to work with Global Technology and IT services companies</h4>
												</div>
												<div className="col-md-4">
													<div className="cm_img cm_img_2">
														<img src={Icon2} alt="Job placement assistance* on course completion" />
													</div>
													<h4>Job placement assistance* on course completion</h4>
												</div>
												<div className="col-md-2">
													<div className="cm_img cm_img_3">
														<img src={Icon3} alt="Global Exposure" />
													</div>
													<h4>Global Exposure</h4>
												</div>
											</div>
										</div>
									</div>
                                    <div className="cms_block cms_banner_2">
										<div className="cms_cont">
											<div className="row">
												<div className="col-md-7">
													<h3 className="cmsb_2">Build a career in Global Technology and work with the best IT companies*</h3>
													<h4 className="cmsb_head">Explore our programs:</h4>
													<div className="cncb_image">
														<img src={banner} alt="Banner 2 Image" className="img-fluid" />
													</div>
													<div className="slide_cnt">
														<h3><span>First IT Careers</span></h3>
														<p>Graduates or pursuing Final Year Graduation in IT streams are eligible</p>
													</div>
													<div className="slide_cnt">
														<h3><span>SwiTch</span></h3>
														<p>Graduates or pursuing Final Year Graduation in Non-IT streams are eligible</p>
													</div>
												</div>
												<div className="col-md-5">
													<div className="b2_cm_img">
														<img src={banner} alt="Banner 2 Image" />
													</div>
												</div>
											</div>
										</div>
									</div>
                                    <div className="cms_block cms_banner_3">
										<div className="cms_cont">
											<div className="row">
												<div className="col-md-7">
													<h3 className="cmsb_3">Learn - Java | .NET | Python | Linux | Big Data | Machine Learning |  Data Engineering | Cloud Services |  Network Administration |  Server Administration</h3>
													<div className="cncb_image">
														<img src={banner3} alt="Banner 3 Image" className="img-fluid" />
													</div>
													<div className="smsb3_cont">
														<div className="smsbc_block">
															<div className="smsbc_blog ">
																<div className="smsbc_icon">
																	<img src={iconCalender} alt="icon image" />
																</div>
																<div className="smsbc_data">
																	<p>Duration: First IT Careers - 3 months | SwiTch - 6 months</p>
																</div>
															</div>
															<div className="smsbc_blog">
																<div className="smsbc_icon">
																	<img src={iconCounsling}alt="icon image" />
																</div>
																<div className="smsbc_data">
																	<p>Book a Free Counselling Session Now</p>
																</div>
															</div>
															<div className="smsbc_blog">
																<div className="smsbc_icon">
																	<img src={iconPhone} alt="icon image" />
																</div>
																<div className="smsbc_data">
																	<p>
																		{/* Give a missed call on <a href="tel:18002108787">1800 210 8787</a> Or  */}
																		<a href="javascript:void(0);" className="gotoForm">Apply Now</a>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-5">
													<div className="b3_cm_img">
														<img src={banner3} alt="Banner 3 Image" />
													</div>
												</div>
											</div>
										</div>
									</div> 
								</div>
							</div>
							<div className="col-md-4 cmc_posa">
								<BannerForm fromPage={"campaign-it-careers"}></BannerForm>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}
export default Slider