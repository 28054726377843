import outProm from '../img/programme_bg.png'
import Step1 from '../img/campaingn/step_1.png'
import Step2 from '../img/campaingn/step_2.png'
import Step3 from '../img/campaingn/step_3.png'
import Step4 from '../img/campaingn/step_4.png'
function OurProgram(){
    return(
        <section className="programe_module clearfix">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row pm_block">
							<div className="col-md-11">
								<h2>Our Programs</h2>
								<p>Our training programs focus on the learner's potential for IT and Tech careers. First IT Careers and SwiTch are the two programs launched by Aptech IT Careers powered by HCL Technologies. These programs are the perfect options for IT and non-IT graduates. These programs go beyond regular certifications and enables viable career opportunities that accelerate professional growth of students.</p>
								<p>First IT Careers program is designed for students from an IT background who have completed their graduation or pursuing their final year of graduation. The duration of this program is 3 months.</p>
								<p>SwiTch, is specifically designed for students from non-IT background who want to establish a successful career in the IT industry. To apply for this program, students must complete their graduation or pursuing their final year of graduation. The duration of this program is 6 months.</p>
								<p>With the help of best-in-class skill-based training and experienced faculty members and industry leaders, students get trained to become an IT professional. We provide necessary assessments to help students choose the right Tech streams and enable them to pursue job-based training facilitated by industry practitioners. We assist students with job placement* at the end of the training programs.</p>
								<p>Explore one of our exciting courses. Fill the form  to speak with our expert. </p>
							</div>
							<div className="col-md-12">
								<div className="row pb_blog">
									<div className="col-md-12">
										<h3>Our Programs</h3>
										<p>By joining Aptech IT Careers powered by HCL Technologies, programs you can specialize in:</p>
									</div>
									<div className="col-md-4">
										<ul>
											<li>Web Application Development (Java)</li>
											<li>Full Stack Development (Java)</li>
											<li>MERN Development (Java)</li>
											<li>MEAN Development (Java)</li>
											<li>Web Application Development (.NET)</li>
											<li>Full Stack Development (.NET)</li>											
										</ul>
									</div>
									<div className="col-md-4">
										<ul>
											<li>Python Web Development</li>
											<li>Data Engineering</li>
											<li>Cloud Engineering</li>
											<li>Network Administration</li>
											<li>Linux</li>
											<li>Windows</li>
										</ul>
									</div>
									<div className="col-md-12 pbb_btn">
										<a href="javascript:void(0);" className="btn-red gotoForm">Apply for a course now</a>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 pm_blog">
								<div className="pm_corner">
									<img src={outProm} alt="Our programs" className="img-fluid"/>
								</div>
							</div>
						</div>
						<div className="row opm_enrolment content-first-it-career">
						<div className="col-md-12">
							<div className="row opme_block">
								<div className="col-md-12">
									<h2>First IT Careers</h2>
									<div className="card-box-1">
										<h3>Course Duration</h3>
										<p>3 months</p>
									</div>
									<div className="card-box-2">
										<h3>Eligibility Criteria to join First IT Careers program</h3>
										<p>Engineering Graduates (including those in their final year)/ final year students studying in BE / B. Tech / MCA / M. Tech / M. Sc (IT/ Computer Science) / B. Sc (IT/ Computer Science) / B. Voc (CS/IT/Software Development) / BCA degree holders.</p>
									</div>
									<div className="card-box-3">
										<h3>Career Paths</h3>
										<ul className="masonrygrid-2">
											<li><p>Analyst</p></li>
											<li><p>Engineer</p></li>
											<li><p>Consultant</p></li>
											<li><p>Sr. Consultant</p></li>
											<li><p>Administrator</p></li>
											<li><p>Consultant/ Subject Matter Expert (SME)</p></li>
											<li><p>Developer</p></li>
											<li><p>FS Cloud Developer</p></li>
											<li><p>Microservices Specialist</p></li>
											<li><p>SW Solution Architect</p></li>
											<li><p>Application Programming Interface (API) Integrator</p></li>
											<li><p>Artificial Intelligence (AI) / Machine Learning (ML) Specialist</p></li>
											<li><p>FS Developer</p></li>
											<li><p>MERN Stack Developer</p></li>
											<li><p>Jr. Developer</p></li>
											<li><p>Sr. Developer</p></li>
											<li><p>Data Scientist</p></li>
											<li><p>Data Engineer</p></li>
										</ul>
										<a href="javascript:void(0);" className="btn-red gotoForm">Apply for a course now</a>
									</div>
									
								</div>
							</div>
						</div>
					</div>
					
					<div className="row opm_enrolment content-switch">
						<div className="col-md-12">
							<div className="row opme_block">
								<div className="col-md-12">
									<h2>SwiTch</h2>
									<div className="card-box-1">
										<h3>Course Duration</h3>
										<p>6 months</p>
									</div>
									<div className="card-box-2">
										<h3>Eligibility Criteria</h3>
										<p>Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year.</p>
									</div>
									<div className="card-box-3">
										<h3>Career Paths</h3>
										<ul className="masonrygrid-2">
											<li><p>Analyst</p></li>
											<li><p>Engineer</p></li>
											<li><p>Consultant</p></li>
											<li><p>Sr. Consultant</p></li>
											<li><p>Developer</p></li>
											<li><p>FS Cloud Developer</p></li>
											<li><p>Microservices Specialist</p></li>
											<li><p>SW Solution Architect</p></li>
											<li><p>Application Programming Interface (API) Integrator</p></li>
											<li><p>Artificial Intelligence (AI) / Machine Learning (ML) Specialist</p></li>
											<li><p>Data Engineer</p></li>
											<li><p>Data Scientist</p></li>
										</ul>
										<a href="javascript:void(0);" className="btn-red gotoForm">Apply for a course now</a>
									</div>
									
								</div>
							</div>
						</div>
					</div>
						
						<div className="row opm_enrolment">
						<div className="col-md-10 offset-md-1">
							<div className="row opme_block">
								<div className="col-md-12">
									<h2>Selection Procedure for First IT Careers and SwiTch programs</h2>
									<ul>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 1</span>
											<span className="opm_img">
												<img src={Step1} alt="Step 1"  className="img-fluid"/>
											</span>
											<span className="opm_title">Online Counselling</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 2</span>
											<span className="opm_img">
												<img src={Step2} alt="Step 1"  className="img-fluid"/>
											</span>
											<span className="opm_title">Registration / Enrolment</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 3</span>
											<span className="opm_img">
												<img src={Step3} alt="Step 1"  className="img-fluid"/>
											</span>
											<span className="opm_title">Online Aptitude Test (Learning and Coding)</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 4</span>
											<span className="opm_img">
												<img src={Step4} alt="Step 1"  className="img-fluid"/>
											</span>
											<span className="opm_title">Choose Technology streams</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-8 offset-md-2">
							<div className="row opme_tagline">
								<div className="col-md-12">
									<p>Interested to join Aptech IT Careers powered by HCL Technologies? <a href="javascript:void(0);" className="gotoForm">Fill the form</a> and our career counsellor will contact you soon</p>
								</div>
							</div>
						</div>
					</div>
					
					</div>
				</div>
			</div>
		</section>
    )
}
export default OurProgram;