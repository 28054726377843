import ProgrameBg from'../img/programme_bg.png';
function OurPrograme() {
    return (
<section className="programe_module clearfix">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row pm_block">
							<div className="col-md-11">
								<h2 className='text-dark'>OUR PROGRAMS</h2>
								<p className='mb-3'>Aptech IT Careers powered by HCL Technologies offers two major courses, namely, <strong>First IT Careers</strong> and <strong>SwiTch</strong>. Our programs go beyond theoretical training and provide the right practical skills to get you started.</p>
								<p className='mb-3'>The First IT Careers program is designed for students from an <strong>IT background</strong> who have completed their graduation or currently pursuing their final year. The duration of this course is 3 months. Our second program, SwiTch, is specifically designed for students from <strong>non-IT background</strong> who want to establish a successful career in the IT industry. To apply, students must have completed their graduation or must be pursuing their final year of graduation. The duration of this course is 6 months.</p>
								<p className='mb-3'>Our programs prepare you for in-demand jobs in the global IT industry. We train you to become an IT professional and assist you with job placement* at the end of the training program.</p>
							</div>
							<div className="col-md-12">
								<div className="row pb_blog">
									<h3 className='d-block w-100 mx-3 mb-3'>PROGRAMS YOU CAN SPECIALIZE IN:</h3>
									<div className="col-md-4">
										<ul>
											<li>Web Application Development (Java)</li>
											<li>Full Stack Development (Java)</li>
											<li>MERN Development (Java)</li>
											<li>MEAN Development (Java)</li>
											<li>Web Application Development (.NET)</li>
											<li>Full Stack Development (.NET)</li>
										</ul>
									</div>
									<div className="col-md-4">
										<ul>
											<li>Python Web Development</li>
											<li>Data Engineering</li>
											<li>Cloud Engineering</li>
											<li>Network Administration</li>
											<li>Linux</li>
											<li>Windows</li>
										</ul>
										
									</div>
									<div className='col-md-12 pbb_btn'>
									<a href="javascript:void(0);" className="btn-red gotoForm mt-3">Apply for a course now</a>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 pm_blog">
								<div className="pm_corner">
									<img src={ProgrameBg} alt="Our programs" className="img-fluid"/>
								</div>
							</div>
						</div>
				</div>
			</div>
			</div>
		</section>
  );
}

export default OurPrograme;