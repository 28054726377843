import FooterLogo from'../img/footer_logo.png';
function AdminFooter() {
    return (
<>
<section className="footer_module">
			<div className="container-fluid">
					<div className="container">						 
						<div className="row fm_copyright">
							<div className="col-md-12">
								<p className="text-center">Copyright &copy; {new Date().getFullYear()} Aptech Ltd. All rights reserved</p>
							</div>
						</div>
					</div>
			</div>
		</section>
		<a href="javascript:" id="return-to-top"><i className="fa fa-chevron-up" aria-hidden="true"></i></a>
</>
  );
}

export default AdminFooter;