import whoare from '../img/who_are_we.png'
function WhoWeAre() {
    return(
        <section className="whoare_module clearfix">
        <div className="container-fluid">
            <div className="row">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 main-heading">
                            <h1>Unlock your dreams today!!</h1>
                    </div>
                    </div>
                    <div className="row wm_block">							
                        <div className="col-md-4 dv-wm">
                            <img src={whoare} alt="Who are we" className="img-fluid"/>
                        </div>
                        <div className="col-md-8">
                            <div className="wmb_conts">									
                                <h2>Who We Are</h2>
                                <img src={whoare} alt="Who are we" className="img-fluid mv-wm"/>
                                <p>Aptech IT Careers powered by HCL Technologies is the first-of-its-kind partnership between two industry stalwarts, Aptech Ltd. and HCL Technologies. We aim to provide job-oriented courses in the field of Information Technology (IT) to both IT and non-IT graduates. Our programs are aimed to provide students with a launch-pad for entry-level job roles in the Global Technology and the IT industry.</p>
                                <p>IT industry is booming. So is the demand for experienced IT professionals who have the right technical skills to work with global giants worldwide. We help you shape your career graph and ensure you build a promising career in the IT industry.</p>								</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default WhoWeAre;