import Advantage from'../img/advantage_image.png';
function Advantages() {
    return (
<section className="advantage_module">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row am_block">
							<div className="col-md-12">
								<h2>Advantages of enrolling with us:</h2>
							</div>
						</div>
						<div className="row am_blog d-flex justify-content-center text-center">
							<div className="col-md-4">
								<div className="amb_left">
									<ul>
										<li>Expert industry trainers</li>
										<li>Latest curriculum</li>
										<li>Modern technologies</li>
										<li>Global exposure</li>
									</ul>
								</div>
							</div>
							<div className="col-md-4">
								<div className="amb_middle">
									<img src={Advantage} alt="Advantages of enrolling" className="img-fluid"/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="amb_right">
									<ul>
										<li>Regular workshops for students' skill development</li>
										<li>Opportunity to work with Global Technology and IT services companies</li>
										<li>Placement assistance*</li>
									</ul>
									<a href="javascript:void(0);" className="am_btn dv-btn gotoForm">Apply for a course now</a>
								</div>
							</div>
							<div className="col-md-12 mv-btn">
								<a href="javascript:void(0);" className="am_btn gotoForm">Apply for a course now</a>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="container">
						<div className="row am_small">
							<div className="col-md-12">
								<p>*Aptech IT Careers powered by HCL Technologies does not guarantee job placements. Placements are at the sole discretion of recruiters.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
  );
}

export default Advantages;