import { dataUpdate } from '../service'
import React, { Component } from "react";
import Header from '../layout/adminHeader';
import Footer from '../layout/adminFooter';
class ContactReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contactList: [],
            contactListBackUp: [],
            searchValue: ""
        }

    }
    componentDidMount() {
        // $(".headerMiddle,.headerRight").addClass("headerMiddle-login");
        var contactData = []
        var siteId = ""
        if (window.sessionStorage.getItem('role') === "Admin") {
            siteId = window.sessionStorage.getItem('username')
        }
        console.log("siteId ", siteId);
        const postData = {
            "functionName": "contactReports",
            "siteId": siteId
        }
        dataUpdate(postData).then((data) => {
            console.log("data.....", data);
            if (data.data.result.contactResultReport.length > 0) {
                let dataContact = data.data.result.contactResultReport
                for (var i = 0; i < data.data.result.contactResultReport.length; i++) {
                    contactData.push({
                        id: i + 1, name: dataContact[i].name, emailId: dataContact[i].emailId, mobileNumber: dataContact[i].mobileNumber,
                        courseName: dataContact[i].courseName, display_name: dataContact[i].display_name, reason: dataContact[i].reason
                    })
                }
                this.setState({
                    contactList: contactData,
                    contactListBackUp: contactData
                });
            }
        });
    }
    searchOnChange(e) {
        this.setState({
            searchValue: e.target.value
        });
        const { name, value } = e.target;
        var contactReportArray = this.state.contactListBackUp
        console.log("event.target.value/////", e.target.value);
        console.log("contactReportArray/////", contactReportArray);
        var filteredData = []
        if (e.target.value !== "") {
            filteredData = contactReportArray.filter((item) => {
                return Object.values(item.id).join('').toLowerCase().includes(e.target.value.toLowerCase()) || Object.values(item.name).join('').toLowerCase().includes(e.target.value.toLowerCase())
                    || Object.values(item.emailId).join('').toLowerCase().includes(e.target.value.toLowerCase()) || Object.values(item.mobileNumber).join('').toLowerCase().includes(e.target.value.toLowerCase())
                    || Object.values(item.courseName).join('').toLowerCase().includes(e.target.value.toLowerCase()) || Object.values(item.display_name).join('').toLowerCase().includes(e.target.value.toLowerCase())
                    || Object.values(item.reason).join('').toLowerCase().includes(e.target.value.toLowerCase())
            })
            console.log("filteredData.....", filteredData);
            this.setState({
                contactList: filteredData
            });
        } else {
            this.setState({
                contactList: this.state.contactListBackUp
            });
        }
    }

    exportFunction = () => {
        console.log('exportFunction....1.....');
        var siteId = ""
        if (window.sessionStorage.getItem('role') === "Admin") {
            siteId = window.sessionStorage.getItem('username')
        }
        const csvRow = [];
        var patientArray = [['S.No', 'Name', 'Email%20Id', 'Mobile%20Number', 'Course%20Name', 'Center%20Name', 'Qualification']];
        let postData = {
            "functionName": "contactReports",
            "siteId": siteId
        }
        console.log('exportFunction....1.....');
        dataUpdate(postData).then((response) => {
            let dataContact = response.data.result.contactResultReport
            for (var i = 0; i < response.data.result.contactResultReport.length; i++) {
                patientArray.push([
                    i + 1,
                    dataContact[i].name,
                    dataContact[i].emailId,
                    dataContact[i].mobileNumber,
                    dataContact[i].courseName,
                    dataContact[i].display_name,
                    dataContact[i].reason
                ]);
            }
            for (var i = 0; i < patientArray.length; ++i) {
                csvRow.push(patientArray[i].join('|'));
            }
            console.log('exportFunction....1.....');
            var csvString = csvRow.join('%0A');
            var a = document.createElement('a');
            a.href = 'data:attachment/csv,' + csvString;
            // a.target = '_Blank';
            a.download = 'report.csv';
            document.body.appendChild(a);
            a.click();
        });
    };

    render() {
        return (
            <section className="contact_report clearfix">
                <div className="container-fluid">
                    <Header/>		
                    <div className="container">
                        <div className="row am_block text-center pb-2">
                            <h2 className="text-center w-100 mb-2">Contact Report</h2>
                            <div className="col-md-3 col-6">
                                <input type='text' placeholder='Search' className="form-control" value={this.state.searchValue} onChange={(e) => this.searchOnChange(e)} />
                            </div>
                            <div className="col-md-3col-md-3 col-6 ml-auto text-right">
                                <button type="button" className="btn btn-dark rounded-0" onClick={() => this.exportFunction()}>Export Report</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Email Id</th>
                                        <th>Mobile Number</th>
                                        <th>Course Name</th>
                                        <th>Center Name</th>
                                        <th>Qualification</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.contactList.length > 0 ?
                                        this.state.contactList.map((obj, i) => (
                                            <tr>
                                                <td>{obj.id}</td>
                                                <td>{obj.name}</td>
                                                <td>{obj.emailId}</td>
                                                <td>{obj.mobileNumber}</td>
                                                <td>{obj.courseName}</td>
                                                <td>{obj.display_name}</td>
                                                <td>{obj.reason}</td>
                                            </tr>
                                        ))

                                        : "No Data Found"}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </section>
        );
    }
}

export default ContactReport;