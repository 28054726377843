import React, {Component} from "react";
import Header from '../layout/adminHeader';
import Footer from '../layout/adminFooter';
import $ from "jquery";
import { getUserAuthentication} from '../service'
class Login extends Component{
  constructor(props) {
    super(props)
    this.state = {
        username : '',
        password: '',
        usernameForgot: '',
        errorMsg:""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
}
componentDidMount() {
  
  $(".inputError").hide();
}
handleInputChange = (event) => {
  const { value, name } = event.target;
  this.setState({
    [name]: value
  });
}
onSubmit = (e) => {
  e.preventDefault();
  let username = this.state.username;
  let password = this.state.password;
  let functionName = "login";
  if(username === '' ) {
    $("#errorMsg").show();
    this.setState({errorMsg : "Username required"})
    $("#username").focus();
  }else if(password === ""){
    $("#errorMsg").show();
    this.setState({errorMsg : "Password required"})
    $("#password").focus();
  }else{
   // $("#errorMsg").hide();
      getUserAuthentication(functionName,username,password).then((data) => {
          console.log('getUserAuthentication-- ',data)
          if(data.success=="1") {
            $("#errorMsg").hide();
              window.sessionStorage.setItem('userId', data.result.userData[0].userId);
              window.sessionStorage.setItem('username', data.result.userData[0].username);
              window.sessionStorage.setItem('role', data.result.userData[0].roleName);
              if (data.result.userData[0].roleName == "Admin" || data.result.userData[0].roleName == "SuperAdmin") {
                  
                  // if(data.result.userData[0].isNewUser === "1" && data.result.userData[0].roleName == "Admin"){
                  //     window.location.href = '/resetPassword'
                  // }else {
                      window.location.href = '/contact_report'
                 // }
              }else {
                  window.location.href = '/login'
              }
          }else {
            $("#errorMsg").show();
            this.setState({errorMsg : "Username Or Password Is InValid."})              
          }
      });
  }
}
  render(){
  return (
   <div className='main_wrapper'>
		 <Header/>
         <div className="am_block text-center pb-2">
            <h2 className="text-center w-100 mb-2 login_head">Login</h2>
            </div>
            <div className='login_inner'>
                <form>
                     <div id="errorMsg" className="alert_div">
                        {this.state.errorMsg}
                     </div>
                    <input type="text" className='form-control' id="username" name="username"placeholder="User Name" onChange={this.handleInputChange}/>
                    <label id="nameError" className="inputError">Please enter username</label>
                    <input type="password" className='form-control' id="password" name="password" placeholder="Password" onChange={this.handleInputChange}/>
                    <label id="passwordErorr" className="inputError">Please enter password</label>
                    <input type="submit" value="Sign Up" className="form-control"  onClick={this.onSubmit}/>
                </form>
            </div>
		<Footer/>
		
   </div>
  );
  }
}

export default Login;
