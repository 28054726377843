import React, { Suspense } from "react";
import Footer from '../layout/footer';
import CareerBanner from '../img/first_it_careers_banner_2.png'
import programs from '../img/our_programs_image.jpg'
import step_1_img from '../img/step_1.png'
import step_2_img from '../img/step_2.png'
import step_3_img from '../img/step_3.png'
import step_4_img from '../img/step_4.png'
import BannerForm from "../home/Banner-form"
import $ from 'jquery';
const Header = React.lazy(() => import("../layout/header"));

function firstItCareers() {
  return (
    <div className='main_wrapper'>
	<Suspense fallback={<div></div>}>
		<Header />
	</Suspense>   
	<section className="breadcrumbs_module">
		<div className="container-fluid">
			<div className="row">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<ul>
								<li><a href="/">Home</a></li>
								<li><a href="javacript:void(0)">Our programs</a></li>
								<li>First IT Careers</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

    <section className="page_intro_module pi_first_it_careers_module clearfix">
		<div className="container-fluid">
			<div className="row">
				<div className="container pi_posr">
					<div className="row">
						<div className="col-md-8">
							<h1 className="pi_heading">First IT Careers</h1>
							<div className="row">
								<div className="col-md-12">
									<div className="pi_banner">
										<img src={CareerBanner} alt="About Banner"
											title="About Banner" className="img-fluid"/>
									</div>
									<div className="pi_content">
										<h3>Our programs provide students with  the launchpad for entry-level job roles in the IT Industry</h3>
									</div>
								</div>
							</div>
						</div>
                       <div className='col-md-4'>
					   <BannerForm fromPage={"first-it-careers"}></BannerForm>
					   </div>
					</div>
					<div className="row pi_abs">
						<div className="container">
							<div className="pi_img">
								<div className="col-md-12">
									<img src={CareerBanner} alt="First IT Careers Banner"
										title="First IT Careers Banner" className="img-fluid"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    <section className="our_programs_module">
		<div className="container-fluid">
			<div className="row">
				<div className="container">
					<div className="row opm_block">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-12">
									<h2>OUR PROGRAMS</h2>
								</div>
							</div>
							<div className="row opmb_col">
								<div className="col-md-6">
									<p>We offer career-oriented programs that enables viable career options that accelerate professional growth in the IT industry.</p>
								</div>
								<div className="col-md-6">
									<p>We train you to become a <strong>successful IT professional</strong> and assist
										you with job placement* at the end of the training programs.</p>
								</div>
							</div>
						</div>

						<div className="col-md-12">
							<div className="row">
								<div className="col-md-12">
									<h3>First IT Careers</h3>
								</div>
							</div>
							<div className="row opmb_col">
								<div className="col-md-6">
									<p>The Aptech IT Careers powered by HCL Technologies First IT Careers program for
										fresh <strong>STEM graduates</strong> or students pursuing the final year of STEM graduation is the
										launchpad for <strong>entry-level job roles in the IT Industry.</strong></p>
								</div>
								<div className="col-md-6">
									<p>The program is of <strong>3 months</strong> with extensive concept learning (Instructor Led and
										Self-paced) along with hands-on practicum (including coding labs, Workware, and
										Mini-projects as applicable for the program track).</p>
								</div>
							</div>
							<div className="row">
								<div className="col-md-9">
									<p>By joining the First IT Careers program, you can choose among the following
										technology and job packs for a career in Global Technology and IT service
										companies:</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="container">
					<div className="row opm_table">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-7">
									<div className="table-responsive">
										<table className="table table-bgcareer">
											<thead>
												<tr>
													<th>Technology</th>
													<th>Job pack</th>
													<th>Hours*</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Cloud Services</td>
													<td>Cloud Engineer</td>
													<td>396</td>
												</tr>
												<tr>
													<td>Network Support Engineer</td>
													<td>Network Administration</td>
													<td>396</td>
												</tr>
												<tr>
													<td rowspan="2">Server Administration</td>
													<td>Linux</td>
													<td>396</td>
												</tr>
												<tr>
													<td>Windows</td>
													<td>396</td>
												</tr>
												<tr>
													<td rowspan="4">Java</td>
													<td>Web Application Development</td>
													<td>360</td>
												</tr>
												<tr>
													<td>Full Stack Development</td>
													<td>360</td>
												</tr>
												<tr>
													<td>MERN Development</td>
													<td>360</td>
												</tr>
												<tr>
													<td>MEAN Development</td>
													<td>360</td>
												</tr>
												<tr>
													<td rowspan="2">.Net</td>
													<td>Web Application Development</td>
													<td>360</td>
												</tr>
												<tr>
													<td>Full Stack Development</td>
													<td>360</td>
												</tr>
												<tr>
													<td>Python</td>
													<td>Python Web Development</td>
													<td>360</td>
												</tr>
												<tr>
													<td>Data Engineering</td>
													<td>Data Engineering (Python / Data Warehousing / Big Data / Hadoop
														/ Tableau)</td>
													<td>360</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="approximately">
										<p>*Approximately</p>
									</div>
								</div>
								<div className="col-md-5">
									<div className="our_programs_image">
										<img src={programs} alt="First IT Careers Banner"
											title="First IT Careers Banner" className="img-fluid"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
   
	<section className="op_courses_module">
		<div className="container-fluid">
			<div className="row">
				<div className="container">
					<div className="row opm_block">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-3 no_pr">
									<div className="row">
										<div className="col-md-12">
											<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
												aria-orientation="vertical">
												<a className="nav-link active" id="opmb_1_tab" data-toggle="pill"
													href="#opmb_1" role="tab" aria-controls="opmb_1"
													aria-selected="true">
													<span className="opct_head">Cloud Services</span>
													<span className="opct_subtitle">Cloud Engineer</span>
												</a>
												<a className="nav-link" id="opmb_2_tab" data-toggle="pill"
													href="#opmb_2" role="tab" aria-controls="opmb_2"
													aria-selected="false">
													<span className="opct_head">Network Support Engineer</span>
													<span className="opct_subtitle">Network Administration</span>
												</a>
												<a className="nav-link" id="opmb_3_tab" data-toggle="pill"
													href="#opmb_3" role="tab" aria-controls="opmb_3"
													aria-selected="false">
													<span className="opct_head">Server Administration</span>
													<span className="opct_subtitle">Linux</span>
												</a>
												<a className="nav-link" id="opmb_4_tab" data-toggle="pill"
												href="#opmb_4" role="tab" aria-controls="opmb_4"
												aria-selected="true">
													<span className="opct_head">Server Administration</span>
													<span className="opct_subtitle">Windows</span>
												</a>
												<a className="nav-link" id="opmb_5_tab" data-toggle="pill"
													href="#opmb_5" role="tab" aria-controls="opmb_5"
													aria-selected="false">
													<span className="opct_head">Java</span>
													<span className="opct_subtitle">Web Application Development</span>
												</a>
												<a className="nav-link" id="opmb_6_tab" data-toggle="pill"
													href="#opmb_6" role="tab" aria-controls="opmb_6"
													aria-selected="false">
													<span className="opct_head">JAVA</span>
													<span className="opct_subtitle">Full Stack Development</span>
												</a>
												<a className="nav-link" id="opmb_7_tab" data-toggle="pill"
												href="#opmb_7" role="tab" aria-controls="opmb_7"
												aria-selected="true">
													<span className="opct_head">JAVA</span>
													<span className="opct_subtitle">MERN Development</span>
												</a>
												<a className="nav-link" id="opmb_8_tab" data-toggle="pill"
													href="#opmb_8" role="tab" aria-controls="opmb_8"
													aria-selected="false">
													<span className="opct_head">JAVA</span>
													<span className="opct_subtitle">MEAN Development</span>
												</a>
												<a className="nav-link" id="opmb_9_tab" data-toggle="pill"
													href="#opmb_9" role="tab" aria-controls="opmb_9"
													aria-selected="false">
													<span className="opct_head">.Net</span>
													<span className="opct_subtitle">Web Application Development</span>
												</a>
												<a className="nav-link" id="opmb_10_tab" data-toggle="pill"
												href="#opmb_10" role="tab" aria-controls="opmb_10"
												aria-selected="true">
													<span className="opct_head">.Net</span>
													<span className="opct_subtitle">Full Stack Development</span>
												</a>
												<a className="nav-link" id="opmb_11_tab" data-toggle="pill"
													href="#opmb_11" role="tab" aria-controls="opmb_11"
													aria-selected="false">
													<span className="opct_head">Python</span>
													<span className="opct_subtitle">Python Web Development</span>
												</a>
												<a className="nav-link" id="opmb_12_tab" data-toggle="pill"
													href="#opmb_12" role="tab" aria-controls="opmb_12"
													aria-selected="false">
													<span className="opct_head">Data Engineering</span>
													<span className="opct_subtitle">Data Engineering</span>
													<span className="opct_tagline">(Python / Data Warehousing / Big Data / Hadoop / Tableau)</span>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-9 no_pl">
									<div className="row">
										<div className="col-md-12">
											<div className="tab-content" id="v-pills-tabContent">
												<div className="card tab-pane fade show active" id="opmb_1" role="tabpanel"
													aria-labelledby="opmb_1_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-1">
														<a data-toggle="collapse" href="#v-collapse-1"
															data-parent="#v-pills-tabContent" aria-expanded="true"
															aria-controls="v-collapse-1">
															<span className="opct_head">Cloud Services</span>
															<span className="opct_subtitle">Cloud Engineer</span>
														</a>
													</div>
													<div id="v-collapse-1" className="collapse show" role="tabpanel"
														aria-labelledby="v-collapse-heading-1"
														data-parent="#v-pills-tabContent">
														<div className="card-body"><h5>Learning Outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Understand cloud computing infrastructure</li><li>Understand security studies, scaling, deployment, and backup, in the context of cloud infrastructure</li><li>Understand the learning techniques of cloud programming and development of system administration skills for the development and maintenance of applications deployed in the cloud</li><li>Work on core Azure services and management tools</li><li>Understand and work on AWS services AWS Elastic Cloud Compute (EC2) Engine</li><li>Understand and work on GCP, GCP Basic Services, Deploying and Managing GCE, Security of GCP, Google Kubernetes Engine (GKE) and Google App Engine (GAE)</li><li>Work on identity access management (IAM) and managed services</li><li>Create a virtual private cloud</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Analyst</li><li>Engineer</li><li>Consultant</li><li>Sr Consultant</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}

												<div className="card tab-pane fade" id="opmb_2" role="tabpanel"
												aria-labelledby="opmb_2_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-2">
													<a data-toggle="collapse" href="#v-collapse-2"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-2">
														<span className="opct_head">Network Support Engineer</span>
													<span className="opct_subtitle">Network Administration</span>
													</a>
												</div>
												<div id="v-collapse-2" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-2"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning Outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Demonstrate the ability to setup, configure, troubleshoot, secure and maintain a microcomputer to include hardware and operating system software </li><li>Design and manage a computer network to include installing, configuring, securing and troubleshooting network devices, protocols and services </li><li>Apply networking skills related to server operating systems, directory services and administrative network policy making</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Analyst</li><li>Engineer</li><li>Consultant</li><li>Sr Consultant</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_3" role="tabpanel"
													aria-labelledby="opmb_3_tab">
													<div className="card-header" role="tab" id="v-collapse-heading-3">
														<a data-toggle="collapse" href="#v-collapse-3"
															data-parent="#v-pills-tabContent" aria-expanded="true"
															aria-controls="v-collapse-3">
															<span className="opct_head">Server Administration</span>
															<span className="opct_subtitle">Linux</span>
														</a>
													</div>
													<div id="v-collapse-3" className="collapse" role="tabpanel"
														aria-labelledby="v-collapse-heading-3"
														data-parent="#v-pills-tabContent">
														<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Install and configure the Linux operating system, manage the resources and security of a computer running Linux at a basic level and make effective use of Unix utilities, and scripting languages</li><li>Configure and manage simple TCP/IP network services on a Linux system and handle basic administrative tasks such as creating and managing users, creating and maintaining file systems, determining and implementing security measures, and performing software installation and package management</li><li>Apply technical knowledge to manage servers, investigate the layout of server file systems, plan, create and manage information services</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Analyst</li><li>Administrator</li><li>Consultant/ SME</li><li>Sr. Consultant / Architect</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
													</div>
												</div>
												{/* /.tab-pane */}

												<div className="card tab-pane fade" id="opmb_4" role="tabpanel"
												aria-labelledby="opmb_4_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-4">
													<a data-toggle="collapse" href="#v-collapse-4"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-4">
														<span className="opct_head">Server Administration</span>
													<span className="opct_subtitle">Windows</span>
													</a>
												</div>
												<div id="v-collapse-4" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-4"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Learn the basics of installing, administrating and maintaining a Windows Server implementation</li><li>Learn administration of user and group accounts, establish Group Policy Management, active directory, network protocols and services such as virtual private networking</li><li>Routing and Remote Access Service, DHCP, DNS, backup, recovery and disaster planning</li><li>FTP Server Installation, Configuration and Management</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Analyst</li><li>Administrator Consultant/ SME</li><li>Sr. Consultant / Architect</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_5" role="tabpanel"
												aria-labelledby="opmb_5_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-5">
													<a data-toggle="collapse" href="#v-collapse-5"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-5">
														<span className="opct_head">Java</span>
														<span className="opct_subtitle">Web Application Development</span>
													</a>
												</div>
												<div id="v-collapse-5" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-5"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Structure and implement HTML/CSS</li><li>Apply intermediate and advanced web development practices</li><li>Implement basic JavaScript and create visualizations in accordance with UI/UX theories</li><li>Develop a fully functioning website and deploy on a web server</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>Developer</li><li>FS Cloud Developer</li><li>Microservices Specialist</li><li>SW Solution Architect</li></ul><p><strong>Horizontal Pathways</strong></p><ul><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_6" role="tabpanel"
												aria-labelledby="opmb_6_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-6">
													<a data-toggle="collapse" href="#v-collapse-6"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-6">
														<span className="opct_head">JAVA</span>
														<span className="opct_subtitle">Full Stack Development</span>
													</a>
												</div>
												<div id="v-collapse-6" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-6"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Structure and implement HTML/CSS</li><li>Apply intermediate and advanced web development practices</li><li>Implement basic JavaScript</li><li>Create visualizations in accordance with UI/UX theories</li><li>Develop a fully functioning website and deploy on a web server</li><li>Find and use code packages based on their documentation to produce working results in a project</li><li>Create webpages that function using external data</li><li>Architect solutions to programming problems by combining visual components and classes</li><li>Develop JavaScript applications that transition between states</li><li>Identify mobile strategies and design for multiple operating systems</li><li>Distinguishing trends in multi-device implementation</li><li>Derive information from data and implement data into applications</li><li>Authenticate, store, and structure user data</li><li>Develop fully working applications that can be used on cross-platforms</li><li>Disambiguate the different structures</li><li>Implement backend API for storing and retrieving data</li><li>Use web sockets to allow multiple synchronous access to a shared dataset</li><li>Secure a database from nefarious incursions</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>FS Developer</li><li>FS Cloud Developer</li><li>Microservices Specialist</li><li>SW Solution Architect</li></ul><p><strong>Horizontal Pathways</strong></p><ul><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_7" role="tabpanel"
												aria-labelledby="opmb_7_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-7">
													<a data-toggle="collapse" href="#v-collapse-7"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-7">
														<span className="opct_head">JAVA</span>
														<span className="opct_subtitle">MERN Development</span>
													</a>
												</div>
												<div id="v-collapse-7" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-7"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Structure and implement HTML/CSS</li><li>Apply intermediate and advanced web development practices</li><li>Implement basic JavaScript</li><li>Create visualizations in accordance with UI/UX theories</li><li>Develop a fully functioning website and deploy on a web server</li><li>Find and use code packages based on their documentation to produce working results in a project</li><li>Create webpages that function using external data</li><li>Architect solutions to programming problems by combining visual components and classes</li><li>Develop JavaScript applications that transition between states</li><li>Identify mobile strategies and design for multiple operating systems</li><li>Distinguishing trends in multi-device implementation</li><li>Derive information from data and implement data into applications</li><li>Authenticate, store, and structure user data</li><li>React functional components, data in functional component, state components, child components, React lifecycle, React routing, uncontrolled elements, performance optimization</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>MERN Stack Developer</li><li>FS Cloud Developer</li><li>Microservices Specialist</li><li>SW Solution Architect</li></ul><p><strong>Horizontal Pathways</strong></p><ul><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_8" role="tabpanel"
												aria-labelledby="opmb_8_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-8">
													<a data-toggle="collapse" href="#v-collapse-8"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-8">
														<span className="opct_head">JAVA</span>
														<span className="opct_subtitle">MEAN Development</span>
													</a>
												</div>
												<div id="v-collapse-8" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-8"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to: </p><ul><li>Structure and implement HTML/CSS</li><li>Apply intermediate and advanced web development practices</li><li>Implement basic JavaScript</li><li>Create visualizations in accordance with UI/UX theories</li><li>Develop a fully functioning website and deploy on a web server</li><li>Find and use code packages based on their documentation to produce working results in a project</li><li>Create webpages that function using external data</li><li>Architect solutions to programming problems by combining visual components and classes</li><li>Develop JavaScript applications that transition between states</li><li>Identify mobile strategies and design for multiple operating systems</li><li>Distinguishing trends in multi-device implementation</li><li>Derive information from data and implement data into applications</li><li>Authenticate, store, and structure user data</li><li>Angular functional components, data in functional component, state components, child components, Angular lifecycle, Angular routing, uncontrolled elements, performance optimization</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>MEAN Stack Developer</li><li>FS Cloud Developer</li><li>Microservices Specialist</li><li>SW Solution Architect</li></ul><p><strong>Horizontal Pathways</strong></p><ul><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_9" role="tabpanel"
												aria-labelledby="opmb_9_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-9">
													<a data-toggle="collapse" href="#v-collapse-9"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-9">
														<span className="opct_head">.Net</span>
														<span className="opct_subtitle">Web Application Development</span>
													</a>
												</div>
												<div id="v-collapse-9" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-9"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Describe what web applications are and how they are created using .NET</li><li>Understand ASP web form, server controls</li><li>Understand the separation between a web page, page code, code-behind pages, page controls, and components</li><li>Connect the web pages with data source and to apply validations on data entered by a user</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><p><strong>Vertical Pathways</strong></p><ul><li>Developer</li><li>FS Cloud Developer</li><li>Microservices Specialist</li><li>SW Solution Architect</li></ul><p><strong>Horizontal Pathways</strong></p><ul><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_10" role="tabpanel"
												aria-labelledby="opmb_10_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-10">
													<a data-toggle="collapse" href="#v-collapse-10"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-10">
														<span className="opct_head">.Net</span>
														<span className="opct_subtitle">Full Stack Development</span>
													</a>
												</div>
												<div id="v-collapse-10" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-10"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Work on ASP.NET MVC, Web API, Entity Framework Code First</li><li>Create web page basics using HTML5, JavaScript and CSS3</li><li>Create responsive UI framework using Bootstrap3, jQuery and Angular JS</li><li>Work on SQL, MySQL databases</li><li>Do backend integration using web services</li><li>Deploy the web app on cloud using agile</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>FS Cloud Developer</li><li>API Integrator</li><li>AI / ML Specialist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_11" role="tabpanel"
												aria-labelledby="opmb_11_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-11">
													<a data-toggle="collapse" href="#v-collapse-11"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-11">
														<span className="opct_head">Python</span>
														<span className="opct_subtitle">Python Web Development</span>
													</a>
												</div>
												<div id="v-collapse-11" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-11"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Programme using core Python</li><li>Create routes, serve static content and files</li><li>Connect templates with models to serve data dynamically</li><li>Work on databases</li><li>Build applications</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Jr Developer</li><li>Sr Developer</li><li>Data Scientist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											<div className="card tab-pane fade" id="opmb_12" role="tabpanel"
												aria-labelledby="opmb_12_tab">
												<div className="card-header" role="tab" id="v-collapse-heading-12">
													<a data-toggle="collapse" href="#v-collapse-12"
														data-parent="#v-pills-tabContent" aria-expanded="true"
														aria-controls="v-collapse-12">
														<span className="opct_head">Data Engineering</span>
														<span className="opct_subtitle">Data Engineering</span>
														<span className="opct_tagline">(Python / Data Warehousing / Big Data / Hadoop / Tableau)</span>
													</a>
												</div>
												<div id="v-collapse-12" className="collapse" role="tabpanel"
													aria-labelledby="v-collapse-heading-12"
													data-parent="#v-pills-tabContent">
													<div className="card-body"><h5>Learning outcomes</h5><p>After successful completion of this program, you'll be able to:</p><ul><li>Design, implement, and evaluate the use of analytic algorithms on sample datasets, explain how a machine-learning model is developed for and evaluated on datasets</li><li>Design and execute experimental data collection and processing and present resulting analyses using best practices in data communications, apply and customize analytics, systems, and techniques to application-specific data engineering requirements and objectives</li><li>Identify tradeoffs among data engineering techniques and contrast design alternatives within the context of specific data engineering application domains</li><li>Program with Python and build complex data architecture, manage data pipelines and data processes to ensure correct implementation of your data architecture</li><li>Use data wrangling to clean, reshape, and unify multiple datasets and large amounts of data to be organized for analysis and automate tasks to optimize the entire data workflow</li></ul><div className="row"><div className="col-md-4"><h5>Career Paths</h5><ul><li>Data Engineer</li><li>Data Scientist</li></ul></div><div className="col-md-4"><h5>Course Duration</h5><ul><li>3 months</li></ul></div><div className="col-md-4"><h5>Eligibility criteria</h5><ul><li>Engineering Graduates (including those in their final year)</li></ul></div></div></div>
												</div>
											</div>
											{/* /.tab-pane */}

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row opm_enrolment">
						<div className="col-md-9 offset-md-3">
							<div className="row opme_block">
								<div className="col-md-12">
									<h2>Enrolment procedure</h2>
									{/* <ol>
										<li>Online Counselling</li>
										<li>Registration / Enrolment</li>
										<li>Online Aptitude Test (Learning and Coding)</li>
										<li>Choose Technology streams</li>
									</ol> */}
									<ul>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 1</span>
											<span className="opm_img">
												<img src={step_1_img} alt="Step 1"  className="img-fluid"/>
											</span>
											<span className="opm_title">Online Counselling</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 2</span>
											<span className="opm_img">
												<img src={step_2_img} alt="Step 2"  className="img-fluid"/>
											</span>
											<span className="opm_title">Registration / Enrolment</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 3</span>
											<span className="opm_img">
												<img src={step_3_img} alt="Step 3"  className="img-fluid"/>
											</span>
											<span className="opm_title">Online Aptitude Test (Learning and Coding)</span>
										</li>
										<li className="d-flex-sm align-items-center-sm">
											<span className="opm_step">Step 4</span>
											<span className="opm_img">
												<img src={step_4_img} alt="Step 4"  className="img-fluid"/>
											</span>
											<span className="opm_title">Choose Technology streams</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-8 offset-md-2">
							<div className="row opme_tagline">
								<div className="col-md-12">
									<p>Interested in any of our courses? Fill in the <a href="javascript:void(0);" className="gotoForm">student enquiry form</a> here and our career counsellor will contact you</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="container">
					<div className="row am_small">
						<div className="col-md-12">
							<p><sup>*</sup>Aptech IT Careers powered by HCL Technologies does not guarantee job placements. Placements are at the sole discretion of recruiters.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>


    <Footer/>
    </div>
  )
}

export default firstItCareers