import React, { Component, Suspense } from "react";
import Footer from '../layout/footer';
import ThankYouContent from "./thankYouContent"
const Header = React.lazy(() => import("../layout/header"));

var EnqNo = window.sessionStorage.getItem('EnqNo')
class ThankYou extends Component {
	componentDidMount() {
		if (EnqNo == "" || EnqNo == "null" || EnqNo == null) {
			window.location.href = '/'
		}
		setTimeout(() => {
			window.location.href = "/"
		}, 60000);
	}
	render() {
		return (
			<div className='main_wrapper'>
				<Suspense fallback={<div></div>}>
					<Header />
				</Suspense>   
				<ThankYouContent />
				<Footer />
			</div> 	
		);
	}
}

export default ThankYou;
