function Placement(){
return(
    <section className="job-placements">
    <div className="container">
        <h2 className="heading">Job Placement Assistance</h2>
        <p>On completing a course from Aptech IT Careers powered by HCL Technologies, our students get work opportunities as:</p>
        <div className="row">
            <div className="col-md-12">
                <div className="masonrygrid">
                    <div className="item item--1x1 blackbg">
                        <p>Data Engineer</p>
                    </div>
                    <div className="item item--2x1 graybg">
                        <p>Data Scientist</p>
                    </div>
                    <div className="item item--2x1 bluebg">
                        <p>Developer</p>
                    </div>
                    <div className="item item--1x1 bluebg">
                        <p>Analyst</p>
                    </div>
                    <div className="item item--1x1 blackbg">
                        <p>Consultant</p>
                    </div>
                    <div className="item item--1x1">
                        <p>Administrator</p>
                    </div>
                    <div className="item item--1x1">
                        <p>Microservices Specialist</p>
                    </div>
                    <div className="item item--1x1 blackbg">
                        <p>Software Solution Architect</p>
                    </div>
                    <div className="item item--2x1 bluebg">
                        <p>Application Programming Interface (API) Integrator</p>
                    </div>
                    <div className="item item--2x1 bluebg">
                        <p>Artificial Intelligence (AI) Specialist</p>
                    </div>
                    <div className="item item--2x1 graybg">
                        <p>Machine Learning (ML) Specialist</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <p className="text-disclaimer">*Aptech IT Careers powered by HCL Technologies does not guarantee job placements. Job offers are at the sole discretion of recruiter.</p>
            </div>
        </div>
    </div>
</section>
)
}
export default Placement