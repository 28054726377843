import React, { Suspense } from "react";
import Slider from "./slider"
import WhoWeAre from "./whoweare"
import OurProgram from "./ourprogram"
import WhyJoint from "./whyJoint"
import Placement from "./jobplacement"

const Header = React.lazy(() => import("./header"));
const Footer = React.lazy(() => import("./footer"));

function Campaingn(){
    return(
        <>
        
<Suspense fallback={<div></div>}>
    <Header />
</Suspense>
<Slider/>
<WhoWeAre/>
<OurProgram/>
<WhyJoint/>
<Placement/>
<Suspense fallback={<div></div>}>
    <Footer />
</Suspense>

        </>
    )
}
export default Campaingn