import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./home/Home.jsx"
import ContactReport from "./home/contact_report.jsx"
import LogIn from "./home/login.jsx";
import ThankYou from "./home/thankYou.jsx";
import ThankYouCampaign from "./Campaign/thankYoucampaign";
import AboutUs from "./ITCareers/aboutUs";
import ContactUs from "./ITCareers/contactUs";
import Faq from "./ITCareers/faq";
import FirstItCareers from "./ITCareers/first-it-careers";
import SwitchBanner from "./ITCareers/switch";
import Campaingn from "./Campaign/home";
import PrivacyPolicy from './ITCareers/privacy-policy.jsx';
import TermsUse from './ITCareers/terms-of-use.jsx';
import ITCareers404 from './ITCareers/it-careers-404';
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact_report" element={<ContactReport />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/our-programs/first-it-careers" element={<FirstItCareers />} />
          <Route path="/our-programs/switch" element={<SwitchBanner />} />
          <Route path="/campaign/it-courses" element={<Campaingn />} />
          <Route path="/campaign/it-courses/thank-you" element={<ThankYouCampaign />} />  
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>     
          <Route path='/terms-of-use' element={<TermsUse/>}/>     
          <Route path='/it-careers-404' element={<ITCareers404/>}/>  
          <Route path="*" element={<ITCareers404/>}/>   
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
