import React, { Component, Suspense } from "react";
import Banner from "../img/about_banner1.png"
import Footer from '../layout/footer';
import AboutImg1 from "../img/about_image_1.jpg"
import AboutImg2 from "../img/about_image_2.png"
import AboutImg3 from "../img/about_image_3.jpg"
import AboutImg4 from "../img/about_image_4.png"
import BannerForm from "../home/Banner-form"
const Header = React.lazy(() => import("../layout/header"));
function AboutUs() {
  return (
    <div className='main_wrapper'>
			<Suspense fallback={<div></div>}>
				<Header />
			</Suspense>        
			 <section className="breadcrumbs_module">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<ul>
									<li><a href="/">Home</a></li>
									<li>About Us</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        <section className="page_intro_module pi_about_module clearfix">
                <div className="container-fluid">
                    <div className="row">
                        <div className="container pi_posr">
                            <div className="row">
                                <div className="col-md-8">
                                    <h1 className="pi_heading">ABOUT US</h1>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="pi_banner">
                                                <img src={Banner} alt="About Banner" title="About Banner" className="img-fluid"/>
                                            </div>
                                            <div className="pi_content">
                                                <h3>Our training programs focus on the learner’s potential for IT and Tech careers</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
								<BannerForm fromPage={"about-us"}></BannerForm>
								</div>
                            </div>
                            <div className="row pi_abs">
                                <div className="container">
                                    <div className="pi_img">
                                        <div className="col-md-12">
                                            <img src={Banner} alt="About Banner" title="About Banner" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_module">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row am_block d-flex justify-content-center align-items-center">
							<div className="col-md-4 ">
								<div className="text-center">
									<img src={AboutImg1} alt="About Image" className="img-fluid" />
								</div>
							</div>
							<div className="col-md-8">
								<div className="amb_cnt">
									<p>Aptech IT Careers powered by HCL Technologies is a partnership between <strong>Aptech Limited</strong>, a pioneer in the non-formal vocational training business in the country, and <strong>HCL Technologies (HCL)</strong>, a leading global IT company. This partnership between the two industry leaders support programs for IT aspirants to become job ready. The program will leverage the HCL Edtech platform to provide content, labs and capstone in engineering, digital technologies, cloud, cybersecurity, and data science.</p>
								</div>
							</div>
						</div>

						<div className="row am_block d-flex justify-content-center align-items-center">
							<div className="col-md-4 order-md-2">
								<div className="text-center">
									<img src={AboutImg2} alt="About Image" className="img-fluid" />
								</div>
							</div>
							<div className="col-md-8 order-md-1">
								<div className="amb_cnt">
									<p>Aptech IT Careers platform has launched 2 major programs of HCL EdTech - (1) <strong>First IT Careers</strong>, an accelerated project-based job certification program for Engineering, B.Sc., and MCA students (fresh graduates or final year) and (2)<strong> SwiTch</strong>, specially designed for graduates from non-IT/CSE (Computer Science) backgrounds with or without work experience to move into IT and Tech Careers.</p>
								</div>
							</div>
						</div>

						<div className="row am_block d-flex justify-content-center align-items-center">
							<div className="col-md-4">
								<div className="text-center">
									<img src={AboutImg3} alt="About Image" className="img-fluid" />
								</div>
							</div>
							<div className="col-md-8">
								<div className="amb_cnt">
									<p>The hybrid training programs will focus on the learner’s potential for <strong>IT and Tech careers</strong>, provide necessary assessments to help them choose the right Tech streams and enable them to pursue <strong>job-based training facilitated by the industry practitioners</strong>. </p>
								</div>
							</div>
						</div>

						<div className="row am_block d-flex justify-content-center align-items-center">
							<div className="col-md-4 order-md-2">
								<div className="text-center">
									<img src={AboutImg4} alt="About Image" className="img-fluid" />
								</div>
							</div>
							<div className="col-md-8 order-md-1">
								<div className="amb_cnt">
									<p>What makes this partnership different is the complementary strengths the companies have to offer. <strong>HCL EdTech platform</strong> leverages the HCL lineage of <strong>45+ years in IT services</strong> and related human capital development for servicing global customers.  At the same time, Aptech has over <strong>three decades of experience</strong> providing coaching and vocational training in various sectors, including media and entertainment, through its network of franchisees and business partners across India.</p>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				<div className="row">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h4 className="benefits-text">Here are a few key benefits of joining Aptech IT Careers powered by HCL Technologies:</h4>
							</div>
						</div>
						<div className="row benefits_lists">
							<div className="col-md-4">
								<ul>
									<li>Expert industry trainers</li>
									<li>Latest curriculum</li>
									<li>Modern technologies</li>
								</ul>
							</div>
							<div className="col-md-5">
								<ul>
									<li>Global exposure </li>
									<li>Regular workshops for students’ skill development</li>
									<li>Opportunity to work with Global Technology and IT services companies</li>
								</ul>
							</div>
							<div className="col-md-3 ambl_lists">
								<ul>
									<li>Placement assistance*</li>
								</ul>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="text-center apl_bnt">
									<a href="#" className="btn_primary gotoForm">Apply for a course now</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="container">
						<div className="row am_small">
							<div className="col-md-12">
								<p>*Aptech IT Careers powered by HCL Technologies does not guarantee job placements. Placements are at the sole discretion of recruiters.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
            <Footer/>
        </div>
  )
}

export default AboutUs