import React, { Component } from "react";
import $ from "jquery";
import { getCourseList, getStateDetails, getCityDetails, dataUpdate, saveItCareerForm } from '../service'
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import DeviceDetector from "device-detector-js";
import Round from'../img/round_1.png';
import Icon1 from'../img/icon1.png';
import Icon2 from'../img/icon2.png';
import Icon3 from'../img/icon3.png';
import banner2 from'../img/banner2.png';
import banner3 from'../img/banner3.png';
import iconCalenderImg from'../img/icon-calender.png';
import iconCounsellingImg from'../img/icon-counselling.png';
import iconPhoneImg from'../img/icon-phone.png';
import BannerForm from "./Banner-form"

import "../css/owl.carousel.min.css";
import "../css/owl.theme.default.min.css";
const deviceDetector = new DeviceDetector();
const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
const device = deviceDetector.parse(userAgent);
console.log('device............',device.device.type);
class Banner extends Component {
	render() {
		var bannerSettings = {
			dots: true,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			};
    return (

		<section className="career_module clearfix">
			<div className="career_module container-fluid">
				<div className="row">
					<div className="container posr">
						<div className="row cm_cnt">
							<div className="col-md-12 cmc_posr">
								<div className="cm_slider owl-carousel owl-theme">
									{/* Slider 1 START */}
									<div className="cms_block cms_banner_1">
										<div className="cncb_image">
											<img src={Round} alt="Banner 1 Image" className="img-fluid" />
										</div>
										<div className="cms_cont">
											<h2>Aptech IT Careers powered by HCL Technologies brings to you career courses in Technology and IT Services that provide:</h2>
											<div className="row">
												<div className="col-md-4">
													<div className="cm_img cm_img_1">
														<img src={Icon1} alt="A chance to work with global technology and IT services companies" />
													</div>
													<h4>A chance to work with Global Technology and IT services companies</h4>
												</div>
												<div className="col-md-4">
													<div className="cm_img cm_img_2">
														<img src={Icon2} alt="Job placement assistance* after successful course completion" />
													</div>
													<h4>Job placement assistance* after successful course completion</h4>
												</div>
												<div className="col-md-2">
													<div className="cm_img cm_img_3">
														<img src={Icon3} alt="Global Exposure" />
													</div>
													<h4>Global Exposure</h4>
												</div>
											</div>
										</div>
									</div>
									{/* Slider 1 END */}
									{/* Slider 2 START */}
									<div className="cms_block cms_banner_2">
										<div className="cms_cont">
											<div className="row">
												<div className="col-md-7">
													<h3 className="cmsb_2">Build a career in Global Technology and work with the best I.T. companies*</h3>
													<h4 className="cmsb_head">Explore our programs:</h4>
													<div className="cncb_image">
														<img src={banner2} alt="Banner 2 Image" className="img-fluid" />
													</div>
													<div className="slide_cnt">
														<h3><span>First I.T. Careers</span></h3>
														<p>Graduates or pursuing Final Year Graduation in I.T. streams are eligible</p>
													</div>
													<div className="slide_cnt">
														<h3><span>SwiTch</span></h3>
														<p>Graduates or pursuing Final Year Graduation in Non-I.T. streams are eligible</p>
													</div>
												</div>
												<div className="col-md-5">
													<div className="b2_cm_img">
														<img src={banner2} alt="Banner 2 Image" />
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* Slider 2 END */}
									{/* Slider 3 START */}
									<div className="cms_block cms_banner_3">
										<div className="cms_cont">
											<div className="row">
												<div className="col-md-7">
													<h3 className="cmsb_3">Learn - Java | .NET | Python | Linux | Big Data | Machine Learning |  Data Engineering | Cloud Services |  Network Administration |  Server Administration</h3>
													<div className="cncb_image">
														<img src={banner3} alt="Banner 3 Image" className="img-fluid" />
													</div>
													<div className="smsb3_cont">
														<div className="smsbc_block">
															<div className="smsbc_blog ">
																<div className="smsbc_icon">
																	<img src={iconCalenderImg} alt="icon image" />
																</div>
																<div className="smsbc_data">
																	<p>Duration: First I.T. Careers - 3 months | SwiTch - 6 months</p>
																</div>
															</div>
															<div className="smsbc_blog">
																<div className="smsbc_icon">
																	<img src={iconCounsellingImg} alt="icon image" />
																</div>
																<div className="smsbc_data">
																	<p>Book a Free Counselling Session Now</p>
																</div>
															</div>
															<div className="smsbc_blog">
																<div className="smsbc_icon">
																	<img src={iconPhoneImg} alt="icon image" />
																</div>
																<div className="smsbc_data">
																	<p>
																		{/* Give a missed call on <a href="tel:18002108787">1800 210 8787</a> Or  */}
																		<a href="javascript:void(0);" className="gotoForm">Apply Now</a>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-5">
													<div className="b3_cm_img">
														<img src={banner3} alt="Banner 3 Image" />
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* Slider 3 END */}
								</div>
							</div>
							
							<div className="col-md-4  cmc_posa">
							<BannerForm fromPage={"homepage"}></BannerForm>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>


// {/* 
// //   <section className="career_module clearfix">
// // 			<div className="container-fluid">
// // 					<div className="container posr banner-mainwrapper">
// //         			<Slider {...bannerSettings}>
// // 						<div className="banner-wrapper">
// // 							<div className="banner-top-img">
// // 								<div className="">
// // 								<img src={Round} alt="Round Image" title="Round Image" className="img-fluid"/>
// // 								</div>
// // 							</div>
// // 							<div className="row cm_cnt">
// // 								<div className="col-md-8">
// // 									<h2> Aptech IT Careers Powered by HCL Technologies brings to you career courses in Technology and IT Services that provides:</h2>
// // 									<div className="row">
// // 										<div className="col-md-4">
// // 											<div className="cm_img cm_img_1">
// // 												<img src={Icon1} alt="A chance to work with global technology and IT services companies" />
// // 											</div>
// // 											<h4>A chance to work with global technology and IT services companies</h4>
// // 										</div>
// // 										<div className="col-md-4">
// // 											<div className="cm_img cm_img_2">
// // 												<img src={Icon2} alt="Job placement assistance* after successful course completion" />
// // 											</div>
// // 											<h4>Job placement assistance* after successful course completion</h4>
// // 										</div>
// // 										<div className="col-md-2">
// // 											<div className="cm_img cm_img_3">
// // 												<img src={Icon3} lt="Global Exposure" />
// // 											</div>
// // 											<h4>Global Exposure</h4>
// // 										</div>
// // 									</div>
// // 								</div>
								
// // 							</div>
// // 						</div>
// // 						<div className="banner-wrapper">
// // 							<div className="banner-top-img">
// // 								<div className="">
// // 								<img src={Round} alt="Round Image" title="Round Image" className="img-fluid"/>
// // 								</div>
// // 							</div>
// // 							<div className="row cm_cnt">
// // 								<div className="col-md-8">
// // 									<h2>Aptech IT Careers Powered by HCL Technologies brings to you career courses in Technology and IT Services that provides:</h2>
// // 									<div className="row">
// // 										<div className="col-md-4">
// // 											<div className="cm_img cm_img_1">
// // 												<img src={Icon1} alt="A chance to work with global technology and IT services companies" />
// // 											</div>
// // 											<h4>A chance to work with global technology and IT services companies</h4>
// // 										</div>
// // 										<div className="col-md-4">
// // 											<div className="cm_img cm_img_2">
// // 												<img src={Icon2} alt="Job placement assistance* after successful course completion" />
// // 											</div>
// // 											<h4>Job placement assistance* after successful course completion</h4>
// // 										</div>
// // 										<div className="col-md-2">
// // 											<div className="cm_img cm_img_3">
// // 												<img src={Icon3} lt="Global Exposure" />
// // 											</div>
// // 											<h4>Global Exposure</h4>
// // 										</div>
// // 									</div>
// // 								</div>
								
// // 							</div>
// // 						</div>
// // 					</Slider>
// // 					<div className="baner-form-pos col-md-4">
// // 						<BannerForm fromPage={"homepage"}></BannerForm>
// // 					</div>
// // 				</div>
// // 			</div>
// // 		  </section> */}

  );
}
}

export default Banner;