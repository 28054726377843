import React, { Component, Suspense } from "react";
import Banner404 from "../img/banner-404.png"
import Footer from '../layout/footer';
const Header = React.lazy(() => import("../layout/header"));
function TermsUse() {
  return (
    <div className='main_wrapper'>
			<Suspense fallback={<div></div>}>
				<Header />
			</Suspense>        
			<div className="container-fluid text-center">
                <img src={Banner404} className="img-fluid"></img>
            </div>
            <Footer/>
        </div>
  )
}

export default TermsUse;