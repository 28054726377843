import React, { Component, Suspense } from "react";
import Banner from "../img/about_banner1.png"
import Footer from '../layout/footer';
import AboutImg1 from "../img/about_image_1.jpg"
import AboutImg2 from "../img/about_image_2.png"
import AboutImg3 from "../img/about_image_3.jpg"
import AboutImg4 from "../img/about_image_4.png"
import BannerForm from "../home/Banner-form"
const Header = React.lazy(() => import("../layout/header"));
function TermsUse() {
  return (
    <div className='main_wrapper'>
			<Suspense fallback={<div></div>}>
				<Header />
			</Suspense>        
			 <section className="breadcrumbs_module">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<ul>
									<li><a href="/">Home</a></li>
									<li>Terms Of Use</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        <section className="page_intro_module pi_terms_module clearfix">
                <div className="container-fluid">
                    <div className="row">
                        <div className="container pi_posr">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="pi_heading">Aptech IT Careers | Terms of Use</h1>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <p>Your use of Aptech IT Career services and Site (referred to collectively as the ‘Services’) is subject to the terms of Use. ‘Aptech’ means Aptech Limited, Aptech House, A-65, MIDC, Marol, Andheri (East), Mumbai 400093, India including its subsidiaries.</p>
                                            <p>The following terms and conditions will be deemed to have been accepted by the User on visiting and usage of the following websites:<a href="https://www.aptechitcareers.com/" target='_blank'> www.aptechitcareers.com</a></p>
                                            <p>You are requested to read them carefully before you use the services of the site.</p>
                                            <ul>
                                                <li>The term User shall refer to the user who has visited the Site and is browsing the Site. The term 'Site' refers to the aforementioned websites belonging to Aptech.</li>
                                                <li>By using the Site, you agree to follow and be bound by the following terms and conditions concerning your use of the Site. Aptech may revise the Terms of Use at any time without notice at its sole discretion.</li>
                                                <li>Aptech may terminate User's access at any time for any reason whatsoever. The provisions regarding a disclaimer of warranty, accuracy of information and indemnification shall survive such termination. Aptech shall continuously monitor access to the Site.</li>
                                                <li>All content, graphics, images, pictures etc. present on the Site is the sole and exclusive property of Aptech. The software, text, images, graphics, video, audio and all kinds of contents used on the Site belong to Aptech. No material from this site shall be copied, modified, reproduced, republished, uploaded, transmitted, adopted, posted or distributed in any form whatsoever. All rights herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright, trademark, Intellectual Property Rights and other applicable laws, and could result in Civil, criminal and/or such other legal remedies/actions. Aptech is a registered trademark of Aptech Limited. This trademark shall not be used in any manner whatsoever.</li>
                                                <li>Aptech does not make any warranties, express or implied, including without limitation, those of merchantability and fitness for a particular purpose, with respect to any information, data, statements, services or products made available on the Site.</li>
                                                <li>Aptech does not accept any responsibility towards the contents and/or information practices of third party Sites which may have links through Aptech's Site.</li>
                                                <li>The Site, and all content, materials, information, software, products and services provided on the Site, are provided on an ‘as is’ and ‘as available’ basis. Aptech disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</li>
                                                <li>Aptech has no responsibility for any damage to a User's computer system or loss of data that result from the download of any content, materials, information from the Site.</li>
                                                <li>Aptech may change or discontinue any aspect, portion of the Site at any time without notice, including its content or features. Aptech reserves the right to change the terms and conditions applicable to the use of the Site. Such changes shall be effective immediately without notice, which shall be placed on the Site.</li>
                                                <li>In any event Aptech will not be liable for damages of any kind, including without limitation, direct, incidental or consequential damages (including, but not limited to, damages for loss of profits, business interruption and loss of programs or information) arising out of the use of Aptech's Site, or any information provided on the Site, or in the Products or Services, any claim attributable to errors, omissions or other inaccuracies in the Product or interpretations thereof.</li>
                                                <li>The User agrees to indemnify, defend and hold Aptech harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site.</li>
                                                <li>Aptech disclaims all warranties as to the accuracy, completeness or adequacy of information contained in the Site.</li>
                                                <li>Aptech makes no warranty (a) that the Site will meet your requirements; (b) that the Site will be available on an uninterrupted, timely, secure, or error-free basis; (c) that the results that may be obtained from the use of the Site or any services offered through the Site will be accurate or reliable.</li>
                                                <li>Any information provided by the User will not be shared with any third party. Aptech reserves the right to use the said information.</li>
                                                <li>The Site may provide links to Web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. You agree that Aptech is not responsible for the availability of, and content provided on, third party Web sites. Aptech is not responsible for third party content accessible through the Site, including opinions, advice, statements and advertisements and User shall bear all risks associated with the use of such content. Aptech is not responsible for any loss or damage of any sort that a User may incur from dealing with any third party.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
  )
}

export default TermsUse;