import React, { Suspense, useEffect } from "react";
import FaqBanner from '../img/faqs_banner.png'
import Footer from '../layout/footer';
import BannerForm from "../home/Banner-form"
import $ from 'jquery';
const Header = React.lazy(() => import("../layout/header"));

// componentDidMount() {
//     $(".card_title").click(function(){
//         $(".collapse_1").toggle();
//       });
//     };
function Faq() {
	// useEffect(()=>{
	// 	$(document).ready(function(){
	// 		$(".card-header").click(function(){
	// 			$(this).toggleClass("collapsed");
	// 		  $(this).next(".card-body").slideToggle();
			
	// 		});
	// 	  });
	// },[])
    
  return (
    <div className='main_wrapper'>
	<Suspense fallback={<div></div>}>
		<Header />
	</Suspense>   
    <section className="breadcrumbs_module">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<ul>
									<li><a href="/">Home</a></li>
									<li>FAQs</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

        <section className="page_intro_module pi_faqs">
			<div className="container-fluid">
				<div className="row">
					<div className="container pi_posr">
						<div className="row">
							<div className="col-md-8">
								<h1 className="pi_heading">FAQs</h1>
								<div className="row">
									<div className="col-md-12">
										<div className="pi_banner">
											<img src={FaqBanner} alt="About Banner" title="About Banner" className="img-fluid"/>
										</div>
										<div className="pi_content">
											<h3>What do you wish to know about today?</h3>
										</div>
									</div>
								</div>
							</div>
                            <div className='col-md-4'>
							<BannerForm fromPage={"faq"}></BannerForm>
							</div>
						</div>
						<div className="row pi_abs">
							<div className="container">
								<div className="pi_img">
									<div className="col-md-12">
										<img src={FaqBanner} alt="About Banner" title="About Banner" className="img-fluid"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

        <section className="page_intro_module pi_faqs">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row">
							<div className="col-md-12 pi_posr">
								<div id="accordion" className="pi_accordian pi_accordian2">
									<div className="card">
										<div className="pia_number">1</div>
										<div className="card-header" data-toggle="collapse" href="#collapse_1">
											<a className="card_title">What are the eligibility criteria to join a course at Aptech IT Careers powered by HCL Technologies?</a>
										</div>
										<div id="collapse_1" className="card-body collapse show" data-parent="#accordion" >
											<div className="pia_cont">
												<p>The eligibility criteria to join a course at Aptech IT Careers powered by HCL Technologies are - </p>
												<ol>
													<li><strong>First IT Careers:</strong> Engineering Graduates - (including those in their final year)</li>
													<li><strong>SwiTch:</strong> Engineering Graduates, Graduates (Non-IT), Diploma Holders (IT/CSE) - including those in their final year.</li>
												</ol>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="pia_number">2</div>
										<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse_2">
											<a className="card_title">What is the duration of courses at Aptech IT Careers powered by HCL Technologies?</a>
										</div>
										<div id="collapse_2" className="card-body collapse" data-parent="#accordion" >
											<div className="pia_cont">
												<p>The duration of courses at Aptech IT Careers powered by HCL Technologies is approximately between 3 to 6 months depending upon the candidate's educational background and the program opted.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="pia_number">3</div>
										<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse_3">
											<a className="card_title">What is the course fee for the First IT Careers program?</a>
										</div>
										<div id="collapse_3" className="card-body collapse" data-parent="#accordion" >
											<div className="pia_cont">
												<p>We will be happy to assist you with the course structure and fee details related to our First IT Careers program. As the next step, please fill out the <a href="javascript:void(0);" className="gotoForm">Enquiry form</a>. One of our Career Counsellors will contact you to guide you further.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="pia_number">4</div>
										<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse_4">
											<a className="card_title">What is the course fee for the SwiTch program?</a>
										</div>
										<div id="collapse_4" className="card-body collapse" data-parent="#accordion" >
											<div className="pia_cont">
												<p>We will be happy to assist you with the course structure and fee details related to our SwiTch program. As the next step, please fill out the <a href="javascript:void(0);" className="gotoForm">Enquiry form</a>. One of our Career Counsellors will contact you to guide you further.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="pia_number">5</div>
										<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse_5">
											<a className="card_title">How will I benefit from joining a course at Aptech IT Careers powered by HCL Technologies?</a>
										</div>
										<div id="collapse_5" className="card-body collapse" data-parent="#accordion" >
											<div className="pia_cont">
												<p>The advantages of joining a course at Aptech IT Careers powered by HCL Technologies are:</p>
												<ol type="a">
													<li>Expert industry trainers</li>
													<li>Latest curriculum</li>
													<li>Modern technologies</li>
													<li>Global exposure</li>
													<li>Regular workshops for students' skill development</li>
													<li style={{marginLeft:"-2px"}}>Opportunity to work with Global Technology and IT services companies</li>
													<li>Placement assistance.*</li>
												</ol>	
											</div>
										</div>
									</div>
									<div className="card">
										<div className="pia_number">6</div>
										<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse_6">
											<a className="card_title">Do I need to be an IT Graduate to join a program at Aptech IT Careers powered by HCL Technologies?</a>
										</div>
										<div id="collapse_6" className="card-body collapse" data-parent="#accordion" >
											<div className="pia_cont">
												<p>To join a program with Aptech IT Careers powered by HCL Technologies, you can be Engineering Graduate, Graduate (Non-IT), or Diploma Holder (IT/CSE) or pursuing your final year.</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="pia_number">7</div>
										<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse_7">
											<a className="card_title">What kind of jobs will I get if I complete a course from Aptech IT Careers powered by HCL Technologies?</a>
										</div>
										<div id="collapse_7" className="card-body collapse" data-parent="#accordion" >
											<div className="pia_cont">
												<p>If you complete a course from Aptech IT Careers powered by HCL Technologies, you will get numerous job opportunities to begin your career with Global Technology and IT services companies in Application Development/ Product Development/ Testing/ Maintenance/ Support roles spanning across business units such as Banking, Healthcare, Financial Services, Infrastructure Management and Engineering Services.</p>
											</div>
										</div>
									</div>
								</div>										
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="container">
						<div className="row am_small">
							<div className="col-md-12">
								<p>*Aptech IT Careers powered by HCL Technologies does not guarantee job placements. Placements are at the sole discretion of recruiters.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

    <Footer/>
</div>
  )
}

export default Faq