 import React, { Suspense, useEffect } from 'react';
 import $ from 'jquery';
// import {useLocation} from "react-router-dom";
import Banner from './Banner'
import WhoWe from './WhoWeAre';
import Report from './contact_report'
import OurPrograme from './OurPrograme';
import Advantages from './Advantage';
import Footer from '../layout/footer';
import Header from "../layout/header";

function Home() {	
	// const search = useLocation().search;
	// const psource = new URLSearchParams(search).get('psource');
	// const ssource = new URLSearchParams(search).get('ssource');
	// if(window.sessionStorage.getItem('psource') === null || window.sessionStorage.getItem('psource') === "null" || window.sessionStorage.getItem('psource') === ""){
	// 	window.sessionStorage.setItem('psource',psource)
	// 	window.sessionStorage.setItem('ssource',ssource)
	// }
	
	useEffect(() =>{
		window.sessionStorage.removeItem('EnqNo')
		
		

		
		
			$(document).ready(function () {
					$('a.banner-link').on('click', function (evt) {
						$('input#txtName').focus();
						evt.preventDefault();
					});
		
				});
	  },[])
  return (
   <div className='main_wrapper main_index_wrapper'>
			<Header />
		<Banner/>
		<WhoWe/>
		<OurPrograme/>
		<Advantages/>		
		<Footer/>
		
   </div>
  );
}

export default Home;
