import Advantage from'../img/advantage_image.png';
function WhyJoint (){
    return(
        <section className="advantage_module">
        <div className="container-fluid">
            <div className="row">
                <div className="container">
                    <div className="row am_block">
                        <div className="col-md-12">
                            <h2>Why join Aptech IT Careers Powered by HCL Technologies?</h2>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center text-center">
                        <div className="col-md-4">
                            <div className="amb_left">
                                <ul>
                                    <li>Best-in-class skill-based training programs</li>
                                    <li>Our programs cover some of the latest job-oriented courses in technology and IT services</li>
                                    <li>We provide job placement assistance* after successful course completion</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="amb_middle">
                                <img src={Advantage} alt="Advantages of enrolling" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="amb_right">
                                <ul>
                                    <li>We arrange regular workshops for students' skill development</li>
                                    <li>We provide an opportunity to learn from leading IT professionals and expert faculty</li>
                                    <li>Students get access to modern technologies and state-of-the-art laboratories.</li>
                                </ul>
                                <a href="javascript:void(0);" className="am_btn dv-btn gotoForm">Apply for a course now</a>
                            </div>
                        </div>
                        <div className="col-md-12 mv-btn">
                            <a href="javascript:void(0);" className="am_btn gotoForm">Apply for a course now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default WhyJoint;