import WhoAre from'../img/who_are_we.png';
function WhoWeAre() {
    return (
<section className="whoare_module clearfix ">
			<div className="container-fluid">
					<div className="container">
						<div className="row wm_block">
							<div className="col-md-4 dv-wm">
								<img src={WhoAre} alt="Who are we" className="img-fluid"/>
							</div>
							<div className="col-md-8">
								<div className="wmb_conts">
									<h2>WHO WE ARE</h2>
									<img src={WhoAre} alt="Who are we" className="img-fluid mv-wm"/>
									<p>Aptech IT Careers powered by HCL Technologies is the first-of-its-kind partnership between two industry leaders, <strong>Aptech Ltd. and HCL Technologies</strong> that aims to provide job-oriented programs in the field of Information Technology (IT) to both <strong>IT and non-IT graduates</strong>. These programs are aimed to provide you with a launch-pad for entry-level job roles in the Global Technology and the IT industry. These programs go beyond regular <strong>certifications</strong> and open up to viable career options that accelerate professional growth of students in the field of Information Technology.</p>
									<p className="wmb_link"><a href="javascript:void(0);" className="gotoForm">Join Aptech IT Careers powered by HCL Technologies</a> and build a promising career in the IT industry.</p>
								</div>
							</div>
						</div>
					</div>
			</div>
		</section>
  );
}

export default WhoWeAre;