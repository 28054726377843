
import $ from "jquery";

$(document).ready(function () {
    $('#return-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 900);
    });
    $('#apply-now-btn').click(function () {
        $('body,html').animate({
            scrollTop: $(".formWrapper").offset().top - 200    
        }, 900);
    });

});
function Footer(){
return(
    <>
    <section className="footer_module">
    <div className="container-fluid">
        <div className="row">
            <div className="container">
                
                <div className="row fm_copyright">
                    <div className="col-md-12">
                        <p className="text-center">Copyright &copy; {new Date().getFullYear()} Aptech Ltd. All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<a href="javascript:" id="return-to-top"><i className="fa fa-chevron-up" aria-hidden="true"></i></a>
<div id="apply-now-btn"></div>
</>

)
}
export default Footer;