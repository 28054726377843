import React, { Component } from "react";
import $ from "jquery";
import { getStateDetails, getCityDetails, dataUpdate, saveItCareerForm, getSiteListDetails,getStateDetailsNew,getCityDetailsNew } from '../service'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import DeviceDetector from "device-detector-js";
import axios from 'axios'
const deviceDetector = new DeviceDetector();
const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
const device = deviceDetector.parse(userAgent);
let flag = false;
class BannerForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			courseList: [],
			listState: [],
			listCity: [],
			siteList: [],
			psource:"",
			ssource:"",
			ip:"",
			courseListNew: [
				{
					qualification: 'Pursuing Graduation in IT Stream',
					course: [{ 'id': '547', 'course': 'Java' }, { 'id': '564', 'course': '.Net' }, { 'id': '565', 'course': 'Python' }, { 'id': '566', 'course': 'Data Engineering' }, { 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				},
				{
					qualification: 'Final year of Graduation in IT Stream',
					course: [{ 'id': '547', 'course': 'Java' }, { 'id': '564', 'course': '.Net' }, { 'id': '565', 'course': 'Python' }, { 'id': '566', 'course': 'Data Engineering' }, { 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				},
				{
					qualification: 'Graduate in IT Stream',
					course: [{ 'id': '547', 'course': 'Java' }, { 'id': '564', 'course': '.Net' }, { 'id': '565', 'course': 'Python' }, { 'id': '566', 'course': 'Data Engineering' }, { 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				},
				{
					qualification: 'Post Graduate in IT Stream',
					course: [{ 'id': '547', 'course': 'Java' }, { 'id': '564', 'course': '.Net' }, { 'id': '565', 'course': 'Python' }, { 'id': '566', 'course': 'Data Engineering' }, { 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				},
				{
					qualification: 'Pursuing Graduation in Non-IT Stream',
					course: [{ 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				},
				{
					qualification: 'Final year of Graduation in Non-IT Stream',
					course: [{ 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				},
				{
					qualification: 'Graduate in Non-IT Stream',
					course: [{ 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				},
				{
					qualification: 'Post Graduate in Non-IT Stream',
					course: [{ 'id': '567', 'course': 'Cloud Services' }, { 'id': '568', 'course': 'Network Administration' }, { 'id': '493', 'course': 'Server Administration' }]
				}
			]
		}
		this.formSubmit = this.formSubmit.bind(this);
	}
	componentDidMount() {
		debugger;		
		const queryParams = new URLSearchParams(window.location.search)
		const psource = queryParams.get("psource")
		const ssource = queryParams.get("ssource")
		this.setState({
			psource: psource,
			ssource:ssource
		});
		$(".inputError").hide();
		$(".pleaseWait").hide();	
		getStateDetailsNew().then((data) => {
			console.log('state........',data);
			this.setState({
				listState: data.result.stateData
			});
		});
		this.getData()
	}	
	getData = async()=>{
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        this.setState({
			ip: res.data.IPv4
		});
    }
	formSubmit(e) {		
		e.preventDefault();		
		let psource = this.state.psource
		let ssource = this.state.ssource
		let centre = $("#councellingCentre").val();
		let name = $("#txtCounsellingName").val();
		let email = $("#txtCounsellingEmail").val();
		let mobileNo = $("#txtCounsellingMobile").val();		
		let qualification = $("#councellingHighestQualification").val()
		let courseId = $("#cmbCounsellingCourse").val();		
		$("#pleaseWaitCounselling").show();		
		$("#submitForm").attr('disabled', true);
		$("#submitbutton").hide()
		let siteId = centre;
		let stateId = $("#councellingState").val();
		let cityId = $("#councellingCity").val();
		let currentUrl = window.location.href;
		let ipAdd = "";
		var contactPost = {
			name: name,
			emailId: email,
			mobileNumber: mobileNo,
			courseId: courseId,
			stateId: stateId,
			cityId: cityId,
			centerId: siteId,
			reason: qualification,
			ipAddress:this.state.ip,
            sSource:(ssource !== null && ssource !== "null" && ssource !== "") ? ssource : this.props.fromPage,
			pSource:(psource !== null && psource !== "null" && psource !== "") ? psource : (device.device.type == "desktop" ? (this.props.fromPage === "campaign-it-careers" ? "others" : "Website") : "Mobile Website"),
			pageUrl:currentUrl,
			functionName: "saveContactUs"
		}
		dataUpdate(contactPost).then((data) => {
			console.log("contactPost>> ", data);
		});
		var emailSend = {
			emailId: email,
			functionName: "contactUsEmailSend"
		}
		dataUpdate(emailSend).then((data) => {
		});
		this.formSubmitFunction(name, email, mobileNo, stateId, siteId, courseId, ipAdd, currentUrl, qualification,cityId)		
	}
	formSubmitFunction(name, email, mobileNo, stateId, siteId, courseId, ipAdd, currentUrl, qualification,cityId) {
		let psource = this.state.psource
		let ssource = this.state.ssource
		$("#submitForm").attr('disabled', true);
		var postData = {
			// "I_Brand_ID": brandId,
			"I_Brand_ID": "117",
			"S_First_Name": name,
			"S_Last_Name": null,
			"S_Email_ID": email,
			"S_Mobile_No": mobileNo,
			"S_Phone_No": null,
			"I_Pref_State_ID": stateId,
			"I_Pref_Center_ID": siteId,
			"I_CourseEnquiryMaster_ID": courseId,
			"S_Comments": null,
			"S_CurrentlyDoing": qualification,
			// "S_Brand_Code": brandCode,
			"S_Brand_Code": "AIC",
			"S_Ip_Address_Location": this.state.ip,
			// "S_Ip_Address_Location": "3.6.11.84",
			"S_Form_Name": null,
			"S_S_Source": (ssource !== null && ssource !== "null" && ssource !== "") ? ssource : this.props.fromPage,
			"S_P_Source": (psource !== null && psource !== "null" && psource !== "") ? psource : (device.device.type == "desktop" ? (this.props.fromPage === "campaign-it-careers" ? "others" : "Website") : "Mobile Website"),
			"S_Network": null,
			"S_Creative": null,
			"S_Keyword": null,
			"S_Placement": null,
			"S_Adposition": null,
			"S_MatchType": null,
			"S_accid": null,
			"S_Page_URL": currentUrl,
			// "S_Page_URL": "http://3.6.11.84/",
			"S_Pages_Visited": "",
			"I_City_ID":cityId
		}
		saveItCareerForm(postData).then((data) => {
			
			try {
				console.log('data...........',data);
				window.sessionStorage.setItem("userMobileNo", mobileNo)
				var parseResult = JSON.parse(data.data.body)
				console.log('parseResult...........',parseResult);
				var enqNo = parseResult.EnqNo
				if (parseResult.result == "Success") {
					enqNo = ((enqNo === ""  || enqNo === "null" || enqNo === null) ? 'NA' : enqNo)
					window.sessionStorage.setItem('EnqNo', enqNo);
					if(this.props.fromPage === "campaign-it-careers"){
						window.location.href = '/campaign/it-courses/thank-you'
					}
					else{
						window.location.href = '/thank-you'
					}					
				}
			} catch (err) {	
				console.log('error.......',err);
				// if(flag === false){
				// 	this.formSubmitFunction(name, email, mobileNo, stateId, siteId, courseId, ipAdd, currentUrl, qualification)
				// 	flag = true;
				// }		
				window.sessionStorage.setItem('EnqNo', 'NA');
				if(this.props.fromPage === "campaign-it-careers"){
					window.location.href = '/campaign/it-courses/thank-you'
				}
				else{
					window.location.href = '/thank-you'
				}	
			}
		})
	}
	nameValidate() {
		let name = $("#txtCounsellingName").val();
		var charactersName = /^[a-zA-Z\s]*$/;
		if (!name || charactersName.test(name) === false) {
			$("#txtCounsellingName").focus()
			$("#txtCounsellingNameError").show();
		}
		else {
			$("#txtCounsellingNameError").hide();			
		}
	}
	mobileValidate() {
		let name = $("#txtCounsellingMobile").val();
		var charactersName = /^[5-9][0-9]{9}$/;
		if (!name || charactersName.test(name) === false) {
			$("#txtCounsellingMobile").focus()
			$("#txtCounsellingMobileError").show();
		}
		else {
			$("#txtCounsellingMobileError").hide();			
		}
	}
	emailValidate() {
		let name = $("#txtCounsellingEmail").val();
		var charactersEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		if (!name || charactersEmail.test(name) === false) {
			$("#txtCounsellingEmail").focus()
			$("#txtCounsellingEmailError").show();
		}
		else {
			$("#txtCounsellingEmailError").hide();
		}
	}
	handleonChangeCourse(e) {
		var value = e.target.value;
		if (value === "0") {
			$("#cmbCounsellingCourseError").show();
		}
		else {
			$("#cmbCounsellingCourseError").hide();
		}
		this.handleonChange()
	}
	stateOnChange(e) {
		var value = e.target.value;
		getCityDetailsNew(value).then((data) => {
			this.setState({
				listCity: data.result.cityData,
				stateId: value,
				status: true,
				siteList: []
			});
		});
		if (value === "0") {
			$("#cmbStateError").show();
		}
		else {
			$("#cmbStateError").hide();
		}
		this.handleonChange()
	}
	async cityOnChange(e) {
		$("#councellingCentre").val("")
		// var value = e.target.value;
		// var stateId = $("#councellingState").val()
		// if (stateId != "") {
		// 	await getSiteListDetails(stateId, value).then((data) => {
		// 		console.log('data.result.siteData...', data.result.siteData);
		// 		if (data.result.siteData.length > 0) {
		// 			this.setState({
		// 				siteList: data.result.siteData
		// 			});
		// 			if(data.result.siteData.length === 1){
		// 				$("#councellingCentre").val(data.result.siteData[0].siteId)
		// 			}
		// 			else{
		// 				$("#submitbuttondisabled").show()
		// 				$("#submitbutton").hide()
		// 				$("#councellingCentre").val("0")
		// 			}
		// 		}
		// 	});
		// }
		// if (value === "0") {
		// 	$("#cmbCityError").show();
		// }
		// else {
		// 	$("#cmbCityError").hide();
		// }
		// not working 3149 
		// working 3033
		this.setState({
			siteList: [{siteId:3149,displayName:'Aptech IT Careers - Own'}]
		},()=>{
			$("#councellingCentre").val(3149)
		});
		this.handleonChange()
	}
	handleonChangeCentre(e) {
		var value = e.target.value;
		if (value === "0" || value === "null" || value === null) {
			$("#cmbSiteError").show();
		}
		else {
			$("#cmbSiteError").hide();
		}
		this.handleonChange()
	}
	handleonChangeQualification(e) {
		var value = e.target.value;
		if (value === "0") {
			$("#cmbQualificationError").show();
			this.setState({
				courseList: []
			})
		}
		else {
			$("#cmbQualificationError").hide();
			let course = this.state.courseListNew.filter((obj) => obj.qualification === value)
			this.setState({
				courseList: course[0].course
			})
		}
		this.handleonChange()
	}
	handleonChange() {
		$("#submitbuttondisabled").show()
		$("#submitbutton").hide()
		let flag = true
		let centre = $("#councellingCentre").val();
		console.log('centre........',centre);
		let name = $("#txtCounsellingName").val();
		let email = $("#txtCounsellingEmail").val();
		let mobileNo = $("#txtCounsellingMobile").val();		
		let qualification = $("#councellingHighestQualification").val()
		let courseId = $("#cmbCounsellingCourse").val();
		let state = $("#councellingState").val();
		let city = $("#councellingCity").val();
		var characters = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		var charactersName = /^[a-zA-Z\s]*$/;
		var mobile = /^\d{10}$/;
		if (!$("#txtCounsellingName").val() || charactersName.test(name) === false) {
			flag = false
		}
		if (!mobileNo || mobile.test(mobileNo) === false) {
			flag = false
		}
		if (mobileNo.length != 10 && (!$("#txtCounsellingMobile").val().match(/^[0-9]{10}$/))) {
			flag = false
		}
		if (!email) {
			flag = false
		}
		if (characters.test(email) == false) {
			flag = false
		}
		if (qualification === "0") {
			flag = false
		}
		if (courseId === "0") {
			flag = false
		}
		if (state === "0") {			
			flag = false
		}
		if (city === "0") {			
			flag = false
		}
		if (centre === "0" || centre === "null" || centre === null) {
			flag = false
		}
		if (flag === true) {
			$("#submitbuttondisabled").hide()
			$("#submitbutton").show()
		}
	}
	render() {
		return (
			<div>
				<div className="cbb_right formWrapper">
					<h2>CAREER GUIDANCE</h2>
					<form>
						<div className="cbbr-field">
							<input tabIndex={1} type="text" placeholder="Name*" autoComplete="off" id="txtCounsellingName" onKeyUp={() => this.nameValidate()} onChange={() => this.handleonChange()} />
							<label id="txtCounsellingNameError" className="inputError" >Please enter a valid name (A-Z, a-z)</label>
						</div>
						<div className="cbbr-field">
							<input tabIndex={2} type="tel" placeholder="Mobile No.*" autoComplete="off" maxLength="10" inputmode="numeric" pattern="[0-9]*" id="txtCounsellingMobile" onKeyUp={() => this.mobileValidate()} onChange={() => this.handleonChange()} />
							<label id="txtCounsellingMobileError" className="inputError">Please enter a valid mobile number</label>
						</div>
						<div className="cbbr-field">
							<input tabIndex={3} type="email" placeholder="Email ID*" autoComplete="off" id="txtCounsellingEmail" onKeyUp={() => this.emailValidate()} onChange={() => this.handleonChange()} />
							<label id="txtCounsellingEmailError" className="inputError">Please enter a valid email ID</label>
						</div>
						<div className="cbbr-field">
							{/* <input type="text" placeholder="Qualification" /> */}
							<select tabIndex={4} placeholder="Qualification" id="councellingHighestQualification" onChange={(e) => this.handleonChangeQualification(e)}>
								<option value="0">Select Qualification*</option>
								<option value="Pursuing Graduation in IT Stream" >Pursuing Graduation in IT Stream</option>
								<option value="Final year of Graduation in IT Stream" >Final year of Graduation in IT Stream</option>
								<option value="Graduate in IT Stream" >Graduate in IT Stream</option>
								<option value="Post Graduate in IT Stream" >Post Graduate in IT Stream</option>
								<option value="Pursuing Graduation in Non-IT Stream" >Pursuing Graduation in Non-IT Stream</option>
								<option value="Final year of Graduation in Non-IT Stream" >Final year of Graduation in Non-IT Stream</option>
								<option value="Graduate in Non-IT Stream" >Graduate in Non-IT Stream</option>
								<option value="Post Graduate in Non-IT Stream" >Post Graduate in Non-IT Stream</option>
							</select>
							<label id="cmbQualificationError" className="inputError">Please select your qualification </label>
						</div>
						<div className="cbbr-field">
							<select tabIndex={5} id="cmbCounsellingCourse" onChange={(e) => this.handleonChangeCourse(e)}>
								<option value={0}>Select Course interested in *</option>
								{this.state.courseList.map((course, k) => (
									<option key={k} value={course.id}>{course.course}</option>
								))}
							</select>
							<label id="cmbCounsellingCourseError" className="inputError">Please select a course</label>
						</div>
						<div className="cbbr-field">
							<select tabIndex={6} id="councellingState" placeholder="State*" onChange={(e) => { this.stateOnChange(e) }}  >
								<option value="0">Select State *</option>
								{this.state.listState.map((list) => (
									<option value={list.stateId} key={list.stateId}>{list.stateName}</option>
								))}
							</select>
							<label id="cmbStateError" className="inputError">Please select your State</label>
						</div>
						<div className="cbbr-field">
							<select tabIndex={7} id="councellingCity" placeholder="City*" onChange={(e) => { this.cityOnChange(e) }}>
								<option value="0">Select City *</option>
								{this.state.listCity.map((list) => (
									<option value={list.cityId} key={list.cityId}>{list.cityName}</option>
								))}
							</select>
							<label id="cmbCityError" className="inputError">Please select your City</label>
						</div>
						{this.state.siteList.length > 1 ?
							<div className="cbbr-field">
								<select tabIndex={8} id="councellingCentre" onChange={(e) => { this.handleonChangeCentre(e) }}>
									<option value="0">Select Centre *</option>
									{this.state.siteList.map((list) => (
										<option value={list.siteId} key={list.siteId}>{list.displayName}</option>
									))}
								</select>
								<label id="cmbSiteError" className="inputError">Please select your nearest centre</label>
							</div>
							:
							<div className="cbbr-field" style={{ display: 'none' }}>
								<input type={"hidden"} id="councellingCentre"/>
								{/* <select id="councellingCentre" placeholder="City*">
									{this.state.siteList.map((list) => (
										<option value={list.siteId} key={list.siteId}>{list.displayName}</option>
									))}
								</select> */}
								<label id="cmbSiteError" className="inputError">Please select your nearest centre</label>
							</div>
						}
						<div className="col-12">
							<GoogleReCaptchaProvider
								reCaptchaKey="6LfM0-ggAAAAAIEM8osTCyvpTjuvbKJovSJJ8H5i"
								scriptProps={{
									async: false, // optional, default to false,
									defer: false, // optional, default to false
									appendTo: 'head', // optional, default to "head", can be "head" or "body",
									nonce: undefined // optional, default undefined
								}}
							>
							</GoogleReCaptchaProvider>
						</div>
						<p className="f_cnt">By clicking on Submit, I allow Aptech IT Careers to call me and send information on Email/ SMS/ Phone.</p>
						{/* <div id="pleaseWaitCounselling" className="pleaseWait">Please wait ..... </div> */}
						<div className="cbbr-field">
							<input type="submit" value="Please wait…" id="pleaseWaitCounselling" disabled className="pleaseWait" />
							<input type="submit" value="SUBMIT" id="submitbuttondisabled" disabled className="disabled-formbtn" />
							<input type="submit" value="SUBMIT" style={{ display: 'none' }} id="submitbutton" onClick={this.formSubmit} />
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export default BannerForm;