import React, { Component } from "react";
import thankyouImg from '../img/contact_us_banner.png'
var EnqNo = window.sessionStorage.getItem('EnqNo')
class ThankYouContent extends Component {
	render() {
		return (
			<div>
				<section className="page_intro_module thank_you_module clearfix">
					<div className="container-fluid">
						<div className="row">
							<div className="container pi_posr">
								<div className="row">
									<div className="col-md-12">
										<h1 className="pi_heading">Thank You</h1>
										<div className="row">
											<div className="col-md-12">
												<div className="pi_banner">
													<img src={thankyouImg} alt="Contact Us" title="Contact Us" className="img-fluid" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row pi_abs">
									<div className="container">
										<div className="pi_img">
											<div className="col-md-12">
												<img src={thankyouImg} alt="Contact us" title="Contact us" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="address_module">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="addbb_location ty_module">
									<p>Thank You for Enquiring with Aptech IT Careers powered by HCL Technologies</p>
									{window.sessionStorage.getItem('EnqNo') !== 'NA' && <p>Your enquiry ID is <strong>{window.sessionStorage.getItem('EnqNo')}</strong></p>}
									<p>Our career counsellor will contact you soon to provide you with free career counselling.</p>
									<p><a href="/our-programs/first-it-careers">Visit our programs page </a> to explore further about Aptech IT Careers powered by HCL Technologies</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default ThankYouContent;
