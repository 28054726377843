import $ from 'jquery';
import '../css/bootstrap.css';
import '../css/font-awesome.min.css'
import '../css/style.css'
// import '../css/style-responsive.css'
import '../css/custom.css'
import Logo from '../img/logo.png';
import Down from '../img/down.png';
import Right from '../img/next.png';
import PhoneIcon from '../img/icon_phone.png';
import { Link } from 'react-router-dom';
import '../css/style_custom.css'
import { useEffect, useState } from 'react';


$(document).on('click', '.menuopen', function () {
    $('.sidebar').addClass("opened");
});
$(document).on('click', '.closesidebar', function () {
    $('.sidebar').removeClass("opened");
});


$(document).on("scroll", function(){
    if
  ($(document).scrollTop() > 100){
      $("#header_module").addClass("shrink");
    }
    else
    {
        $("#header_module").removeClass("shrink");
    }
});

function Header() {

    useEffect(() => {
        $(document).ready(function () {
            $(".navbar-collapse").addClass("Ontoggle");
            $("#isToggle").click(function () {
                $(".Ontoggle").slideToggle();
                $("#isToggle").toggleClass("bar_toggle");
            });
            $('#nav_programs').click(function () {
                $(this).siblings('.nav_link_subUl').slideToggle()
         
             });
        })

        $(function(o) {
            "use strict";
            var e = o(window);
            o(".gotoForm").click(function() {
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? o("html,body").animate({
                scrollTop: o(".formWrapper").offset().top - 100
              }, 1500) : o("html,body").animate({
                scrollTop: o(".formWrapper").offset().top - 200
              }, 1500)
            });
          });
          
    }, [])
    const logout = () => {
        window.sessionStorage.removeItem('userId');
        window.sessionStorage.removeItem('username');
        window.sessionStorage.removeItem('role');
    }
    return (
        
        <section className="header_module" id="header_module">
            <div className="container-fluid">
                <div className='row'>
                <div className="container">
                    <nav className="navbar">
                        <a className="navbar-brand" href="/">
                            <img src={Logo} className="img-fluid logo_img" alt="fireSpot" />
                        </a>
                        <div className="hm_phone">                            
                            {/* <a href="tel:18002108787" className='d-flex align-items-center'>
                                <img src={PhoneIcon} alt="Phone Icon" title="Phone Icon" />
                                <span className='missed_span'>
                                <span className='give_call'>Give a missed call on</span>
                                <span>1800 210 8787</span>
                                </span>                                
                            </a> */}
                        </div>
                        <div className="hm_btn">
                            <a href="javascript:void(0);" className="gotoForm">Apply Now</a>
                        </div>
                        {/* <div className='logout_icon'>
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <div className='drop_logout'>
                   <ul className='nav mb-0'>
                   {(window.sessionStorage.getItem('userId')!== null && window.sessionStorage.getItem('userId')!== "null" && window.sessionStorage.getItem('userId')!== undefined)?
                    <li  className='log_Out'  onClick={logout}><a href="/">Log Out</a></li>
                    :
                    <li className='log_Out'><a href="/login" className="loginbtn">Login</a></li>                
    }
         <li className='log_Out'>
                   <Link to="./login">Log Out</Link>
                   </li>
                   </ul>
                </div>
                </div> */}
                        <button id='isToggle' className="navbar-toggler menuopen" type="button" >
                            <span></span>
                            <span className='middle-navmenu'></span>
                            <span></span>
                        </button>

                        {/* <div className="navbar-collapse" >
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="#">For Learners</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">For Business</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">For Partners</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">About HCL CaSh</a>
                        </li>
                        {(window.sessionStorage.getItem('userId')!== null && window.sessionStorage.getItem('userId')!== "null" && window.sessionStorage.getItem('userId')!== undefined)?
                    <li  className='log_Out'  onClick={logout}><a href="/">Log Out</a></li>
                    :
                        <li className="nav-item">
                            <a className="nav-link" href="/login">Login</a>
                        </li>
                        }
                     
                    </ul>
                </div> */}


                        <div className="sidebar">
                            <a className="closesidebar"><svg viewBox="0 0 64 64"><line x1="60.92" x2="3.08" y1="5.92" y2="58.08" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth={4} /><line x1="3.08" x2="60.92" y1="5.92" y2="58.08" fill="none" stroke="#010101" strokeMiterlimit="10" strokeWidth={4} /></svg></a>
                            {/* <img src={logo} className="sb-logo" alt="logo"/> */}
                            {/* <div className="navbar-collapse" > */}
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/about-us">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" id='nav_programs'>Our Programs <span><img src={Down} alt="Down" width={20} /></span></a>
                                    <ul className="nav_link_subUl">
                                        <li className="nav-item">
                                            <a className="nav-link pl-0" href="/our-programs/first-it-careers"><span><img src={Right} alt="" width={20} className="pr-2"/></span>First IT Careers</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link pl-0" href="/our-programs/switch"><span><img src={Right} alt="" width={20} className="pr-2"/></span>SwiTch</a>
                                        </li>
                                       

                                    </ul>

                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/faqs">FAQs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/contact-us">Contact Us</a>
                                </li>
                                {/* {(window.sessionStorage.getItem('userId')!== null && window.sessionStorage.getItem('userId')!== "null" && window.sessionStorage.getItem('userId')!== undefined)?
                    <li  className='log_Out'  onClick={logout}><a href="/">Log Out</a></li>
                    :
                        <li className="nav-item">
                            <a className="nav-link login-btn" href="/login">Login</a>
                        </li>
                        } */}

                            </ul>
                            {/* </div> */}
                        </div>

                    </nav>
                </div>
                </div>
            </div>
        </section>

    );
}

export default Header;