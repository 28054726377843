import axios from "axios";

const awsLambdUrl = 'https://capdfcfgij.execute-api.ap-south-1.amazonaws.com/itCareerProd' 

const saveItCareerFormURL = "https://capdfcfgij.execute-api.ap-south-1.amazonaws.com/itCareerProd/enquireform"
export function saveItCareerForm(postData) {
  console.log(saveItCareerFormURL, postData)
  return axios.post(saveItCareerFormURL, postData)
    .then((data) => data)
    .then((res) => res)
}

const deleteAwardURL = awsLambdUrl + '/getdetails'
export function dataUpdate(postData) {
  return axios.post(deleteAwardURL, postData)
    .then((data) => data)
    .then((res) => res)
}

export function getCourseList(functionName, siteId) {
    return fetch(awsLambdUrl + '/getdetails?functionName=' + functionName + '&siteId=' + siteId)
      .then(data => data.json())
      .then(res => res);
  }

  export function getStateDetails() {
    return fetch(awsLambdUrl + '/getdetails?functionName=state')
      .then(data => data.json())
      .then(res => res);
  }
  export function getStateDetailsNew() {
    console.log(awsLambdUrl + '/getdetails?functionName=stateNew');
    return fetch(awsLambdUrl + '/getdetails?functionName=stateNew')
      .then(data => data.json())
      .then(res => res);
  }
  export function getCityDetails(stateId) {
    return fetch(awsLambdUrl + '/getdetails?functionName=city&stateId='+stateId)
      .then(data => data.json())
      .then(res => res);
  }
  export function getCityDetailsNew(stateId) {
    console.log(awsLambdUrl + '/getdetails?functionName=cityNew&stateId='+stateId);
    return fetch(awsLambdUrl + '/getdetails?functionName=cityNew&stateId='+stateId)
      .then(data => data.json())
      .then(res => res);
  }
  export function getUserAuthentication(functionName, username, password) {
    return fetch(awsLambdUrl + '/getdetails?functionName=' + functionName + '&username=' + username + '&password=' + password)
      .then((data) => data.json())
      .then((res) => res)
  }


  export function getSiteListDetails(stateId,cityId) {    
    return fetch(awsLambdUrl + '/getdetails?functionName=siteList&stateId='+stateId+'&cityId='+cityId)
      .then(data => data.json())
      .then(res => res);
  }