import FooterLogo from'../img/footer_logo.png';
import $ from "jquery";

$(document).ready(function () {
    $('.gotoForm').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 900);
    });

});


function Footer() {
    return (
<>
<section className="footer_module">
		<div className="container-fluid">
			<div className="row">
				<div className="container">
					<div className="row fm_block">
						<div className="col-lg-5 col-md-4">
							<div className="fm_logo">
								<a href="/" className="fmb_link">
								<img src={FooterLogo} alt="Logo"  className="img-fluid fml_logo"/>
								</a>
							</div>
						</div>
						<div className="col-lg-4 col-md-5">
							<div className="row fm_menu">
								<div className="col-md-6 col-sm-6">
									<ul>
										<li><a href="/">Home</a></li>
										<li><a href="javascript:void(0);" className="gotoForm">Student Enquiry</a></li>
										<li><a href="/faqs">FAQs</a></li>
										<li><a href="/contact-us">Contact Us</a></li>
										
									</ul>
								</div>
								<div className="col-md-6 col-sm-6">
									<ul>
										<li><a href="/terms-of-use">Terms & Conditions</a></li>
										<li><a href="javascript:void(0);" className="gotoForm">Apply Now</a></li>
										<li><a href="/privacy-policy">Privacy Policy</a></li>
										{/* <li><a href="tel:18002108787">Toll Free number</a></li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="fmb_social">
								<h3>FOLLOW US</h3>
								<ul>
									<li><a href="https://www.facebook.com/Aptech-It-Careers-110044785048197" target="_blank"><i className="fa fa-facebook-f" aria-hidden="true"></i></a></li>
									<li><a href="https://twitter.com/AptechITCareers" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
									<li><a href="https://www.instagram.com/aptechitcareersofficial_/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
									{/* <li><a href="#" target="_blank"><i className="fa fa-youtube" aria-hidden="true"></i></a></li> */}
									<li><a href="https://www.linkedin.com/company/aptech-it-careers/about/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row fm_copyright">
						<div className="col-md-12">
							<p className="text-center">Copyright &copy; {new Date().getFullYear()} Aptech Ltd. All rights reserved</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    <div id="apply-now-btn"></div>
	<div className='cursor-pointer' id="return-to-top"><i className="fa fa-chevron-up" aria-hidden="true"></i></div>
</>
  );
}

export default Footer;