import '../css/bootstrap.css';
import '../css/font-awesome.min.css'
import '../css/style.css'
import Logo from'../img/logo.png';
import PhoneIcon from'../img/icon_phone.png';
function AdminHeader() {
    const logout=()=>{
        window.sessionStorage.removeItem('userId');
        window.sessionStorage.removeItem('username');
        window.sessionStorage.removeItem('role');
      }
    return (
<section className="header_module front_page">
<div className="container-fluid">
        <div className="container">
            <nav className="navbar">
                <a className="navbar-brand" href="/">
                    <img  src={Logo}  className="img-fluid logo_img" alt="fireSpot"/>
                </a>
               
                {/* <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="#">Menu 1</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Menu 2</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Menu 3</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Menu 4</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Menu 5</a>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                            Menu 6
                            </a>
                            <ul className="dropdown-menu">
                                <li className="nav-item"><a className="dropdown-item" href="#">Menu 6:1</a></li>
                                <li className="nav-item"><a className="dropdown-item" href="#">Menu 6:2</a></li>
                                <li className="nav-item"><a className="dropdown-item" href="#">Menu 6:3</a></li>
                            </ul>
                        </li>
                    </ul>
                </div> */}
                <div className='logout_icon ml-auto'>
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <div className='drop_logout'>
                   <ul  className='nav'>
                   {window.sessionStorage.getItem('userId')!== null && window.sessionStorage.getItem('userId')!== "null" ?
                    <li  className='log_Out' onClick={logout}><a href="/" >Log Out</a></li>
                    :
                    <li className='log_Out'><a href="/login" className="loginbtn">Login</a></li>                
    }
                   
                   </ul>
                </div>
                </div>
            </nav>
    </div>
</div>
</section>
  );
}

export default AdminHeader;