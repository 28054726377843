import React, { Suspense } from "react";
import Footer from '../layout/footer';
import Contactus from '../img/contact_us_banner.png'
import BannerForm from "../home/Banner-form"
const Header = React.lazy(() => import("../layout/header"));

function ContactUs() {
  return (
    <div className='main_wrapper'>
		<Suspense fallback={<div></div>}>
			<Header />
		</Suspense>   
        <section className="breadcrumbs_module">
			<div className="container-fluid">
				<div className="row">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<ul>
									<li><a href="/">Home</a></li>
									<li>Contact Us</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="page_intro_module pi_contact_module clearfix">
			<div className="container-fluid">
				<div className="row">
					<div className="container pi_posr">
						<div className="row">
							<div className="col-md-8">
								<h1 className="pi_heading">Contact Us</h1>
								<div className="row">
									<div className="col-md-12">
										<div className="pi_banner">
											<img src={Contactus} alt="Contact Us" title="Contact Us" className="img-fluid" />
										</div>
										{/* <div className="pi_content pi_contact">
											<h3>Connect with Us</h3>
											<p>Give a missed call on: <a href="tel:18002108787" className="link-customer">1800 210 8787</a></p>
											<p>We will call you back for free counselling.</p>
										</div> */}
									</div>
								</div>
							</div>
							<div className='col-md-4'>
							<BannerForm fromPage={"contact-us"}></BannerForm>
							</div>
						</div>
						<div className="row pi_abs">
							<div className="container">
								<div className="pi_img">
									<div className="col-md-12">
										<img src={Contactus} alt="Contact us" title="Contact us" className="img-fluid" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="address_module">
			<div className="container">
				<div className="row">
									<div className="col-md-12">
										<div className="addbb_location">
											<address>
												<h2>Customer Care</h2>
												<p>Contact our customer care representative in case of complaints/grievances.</p>
												<p><a href="https://docs.google.com/forms/d/e/1FAIpQLSeIRCXFO5IyrWEoZJmBotgoqQf8XEOVT1DnoSWx45iyaaDsTw/viewform" target="_blank" className="link-submit">Submit your complaint</a><br />
													or write at <a href="mailto:customercare@aptech.ac.in" target="_blank" className="link-customer">customercare@aptech.ac.in</a></p>
												<p>We'll review your complaint/grievance and work with you to resolve the issue at the earliest.</p>

											</address>
										</div>
										<style>
											
											
											
										</style>
									</div>
				</div>
			</div>
		</section>
	
	
        <Footer/>
    </div>
  )
}

export default ContactUs